import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { Switch } from '../../ui-components'
import { OneShotButton } from '../../js'
import { useSocket } from '../../contexts/SocketContext'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

const SwitchAndResetButton = ({ id, label }) => {
    const { name: oxinId } = useOxinSelected()
    const socketClient = useSocket()
    const [reset, setReset] = useState()
    useEffect(() => {
        const resetButton = new OneShotButton(
            `implement-button-off-${id}`,
            { stateValue: 0 },
            socketClient,
            oxinId
        )

        return () => {
            resetButton.destroy()
        }
    }, [])
    const _handlerReset = () => {
        setReset(Date.now())
    }
    return (
        <Grid
            {...{
                xs: 12,
                sm: 6,
                px: { sm: 2, xs: 1 },
                py: { sm: 2, xs: 1 },
                gap: { sm: 3, xs: 2 },
                display: 'grid',
            }}
        >
            <Switch
                id={id}
                label={label}
                type="range"
                className="smaller"
                reset={reset}
                config={{ min: -100, max: 100, fix: 0, threshold: 10 }}
            />
            <Button
                id={`implement-button-off-${id}`}
                variant="contained"
                size="large"
                data-action={id}
                data-state="0"
                onClick={_handlerReset}
                startIcon={<FontAwesomeIcon icon={faPowerOff} className="fa-xl" />}
            >
                OFF {label}
            </Button>
        </Grid>
    )
}

const Fan = (props) => {
    const elements = [
        { id: 'fan_control_radiator', label: 'ENGINE FAN' },
        { id: 'fan_control_oil', label: 'HYD-OIL FAN' },
    ]

    return (
        <Grid container sx={{ width: '100%' }}>
            {elements.map(({ id, label }) => (
                <SwitchAndResetButton id={id} label={label} />
            ))}
        </Grid>
    )
}

Fan.propTypes = {}

export default Fan
