import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { TabPanel, a11yProps } from '../../ui-components'
import CameraPage from '../Camera/Camera'
import { SettingParameters } from './SettingParameters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears, faCamera, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './Tabs.module.scss'

export const DriveTabs = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Paper sx={{ width: '100%' }} className={styles.root} elevation={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={styles.header}
                    aria-label="basic tabs example"
                >
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faGears} size="sm" />}
                        iconPosition="start"
                        label={'Settings'}
                        {...a11yProps('drive-settings', 0)}
                    />
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faCamera} size="sm" />}
                        iconPosition="start"
                        label={'Camera'}
                        {...a11yProps('drive-camera', 1)}
                    />
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faMapMarkerAlt} size="sm" />}
                        iconPosition="start"
                        label={'Map'}
                        {...a11yProps('drive-map', 2)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} id={'drive-setting'}>
                <SettingParameters />
            </TabPanel>
            <TabPanel value={value} index={1} id={'drive-camera'}>
                <Box
                    sx={{
                        height: 'calc(100vh - 10rem)',
                        textAlign: 'center',
                        fontSize: '2rem',
                    }}
                >
                    <CameraPage />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2} id={'drive-map'}>
                <Box
                    sx={{
                        height: 'calc(100vh - 10rem)',
                        textAlign: 'center',
                        fontSize: '2rem',
                        padding: '5rem',
                    }}
                >
                    Coming soon
                </Box>
            </TabPanel>
        </Paper>
    )
}
