import React, { useEffect, useRef, useState } from 'react'

//https://codepen.io/Kelderic/pen/Qjagjz
//https://codesandbox.io/s/w7rwn53eo?file=/styles.js:849-860
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'

import styles from './LabelToggleButtonGroup.module.scss'

import { SxProps, Theme } from '@mui/material/styles'

const SwitchSelection = ({ ...props }) => {
    const classes = classNames(styles.root, {
        [styles.switchSelection]: true,
    })
    return <span {...props} className={classes}></span>
}

export const LabelToggleButtonGroup = ({
    label = '',
    selected,
    disabled = false,
    className = '',
    testId = 'label-toggle-button-group',
    onChange = () => {},
    options = [],
    sx,
}: {
    sx?: SxProps<Theme>
    label: string
    selected: string
    disabled: boolean
    className?: string
    testId: string
    onChange: Function
    options?: [{ name: string; value: any }]
}) => {
    const classes = classNames(styles.root, {}, className)

    const [value, setValue] = useState(selected)
    const [sizeSelected, setSizeSelected] = useState({})
    const toggleGroup = useRef()

    const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
        console.log(event, newValue, value)
        if (newValue !== value && newValue !== null) {
            setValue(newValue)
            onChange(newValue)
        }
    }

    useEffect(() => {
        const list = toggleGroup?.current?.children || []
        let buttonSelected
        for (let index = 0; index < list.length; index++) {
            const item = list[index]
            if (item.value == value) {
                buttonSelected = item
            }
        }
        setSizeSelected({
            width: buttonSelected?.offsetWidth + 6,
            height: buttonSelected?.offsetHeight + 6,
            transform: `translate(${buttonSelected?.offsetLeft - 3}px,-3px)`,
        })
    }, [toggleGroup, value])

    return (
        <Stack direction="row" sx={{ minWidth: 0, display: 'block', py: 2, pr: 6 }}>
            <Typography
                variant="button"
                display="block"
                gutterBottom
                sx={{ textTransform: 'uppercase' }}
            >
                {label}
            </Typography>
            <ToggleButtonGroup
                value={value}
                exclusive
                disabled={disabled}
                onChange={handleChange}
                aria-label="text alignment"
                className={classes}
                ref={toggleGroup}
                size="large"
                fullWidth={true}
                sx={{ height: '3rem' }}
            >
                {options.map(({ value: val, name }, index) => (
                    <ToggleButton
                        key={`${index}-${val}`}
                        value={val}
                        aria-label={name}
                        selected={value == val}
                        fullWidth={true}
                        size="large"
                    >
                        <span style={{ color: value == val ? 'white' : 'inherit' }}>{name}</span>
                    </ToggleButton>
                ))}
                <SwitchSelection style={{ ...sizeSelected }} />
            </ToggleButtonGroup>
        </Stack>
    )
}
