export class TrackElement {
    constructor(container_id, track_controller) {
        this.is_released = true
        this.el = document.getElementById(container_id)
        this.el.classList.add('draggable')

        this.el.addEventListener('mousedown', () => {
            track_controller.startPublishing(this)
        })
        this.el.addEventListener('mouseup', () => {
            track_controller.stopPublishing(this)
        })
        const containerId = this.el.parentElement.id

        this.draggie = new Draggabilly(this.el, {
            containment: `#${containerId}`,
            axis: 'y',
        })

        this.draggie.on('dragStart', () => {
            track_controller.startPublishing(this)
        })

        this.draggie.on('dragEnd', () => {
            track_controller.stopPublishing(this)
        })

        this.$box = this.el.parentElement
    }

    getValue() {
        const fraction =
            this.draggie.position.y / ((this.$box.offsetHeight - this.el.offsetHeight) / 2)
        return Math.round(-fraction * 100)
    }

    resetPosition() {
        this.draggie.setPosition(0, 0)
        this.is_released = true
    }

    get action() {
        return this.el.getAttribute('data-action')
    }
}

export class TrackController {
    _handlerMove = () => {}
    constructor(left_track, right_track, _handlerMove) {
        this.publisher = undefined // callback triggered by setInterval()
        this._handlerMove = _handlerMove
        this.lhtc = new TrackElement(left_track, this)
        this.rhtc = new TrackElement(right_track, this)
        this.action = this.lhtc.action
    }

    publishCommand() {
        const payload = {
            action: this.action,
            left_track: this.lhtc.getValue(),
            right_track: this.rhtc.getValue(),
        }
        this._handlerMove(payload)
    }

    startPublishing(track) {
        track.is_released = false

        if (!this.publisher) {
            this.publisher = setInterval(this.publishCommand.bind(this), 80)
        }
    }

    stopPublishing(track) {
        track.resetPosition()

        if (this.lhtc.is_released && this.rhtc.is_released) {
            clearInterval(this.publisher) // clear interval publisher
            this.publisher = undefined // remove interval publisher
            this.publishCommand() // send 0 value to the cloud
        }
    }
}
