import React, { useEffect, useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import { useOxinSelected, useOxinLastLogs, useOxinLastReportData } from '../../hooks'
import { useSocket } from '../../contexts/SocketContext'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Badge from '@mui/material/Badge'
import CircularProgress from '@mui/material/CircularProgress'
import { transformParametersInfo } from '../../utils'

const loggerDivId = 'container-logger'

export default function Info() {
    const { name: oxinId } = useOxinSelected()
    const vehicleId = oxinId?.toLowerCase()
    const [loadSocket, setLoadSocket] = useState(false)
    const [logsReady, setLogsReady] = useState(false)
    const socketClient = useSocket()

    useEffect(() => {
        if (socketClient?.connected && !loadSocket && logsReady) {
            const watchDogRef = (message) => {
                console.log('log_message', message, logsReady, message.oxinId, vehicleId)
                const theDiv = document.getElementById(loggerDivId)
                if (message && message.oxinId === vehicleId && theDiv && logsReady) {
                    const content = `<div class="log ${message.type || null}">
                        <div>${new Date().toLocaleTimeString()}</div>
                        <div>${message.report}</div>
                    </div>`
                    theDiv.removeChild(theDiv.getElementsByTagName('div.log')[0])
                    theDiv.innerHTML = theDiv.innerHTML + content
                    theDiv.scrollTop = theDiv.scrollHeight
                }
            }
            socketClient.off('log_message', watchDogRef)
            socketClient.on('log_message', watchDogRef)
            setLoadSocket(true)
        }
    }, [socketClient, logsReady, loadSocket])

    useEffect(() => {
        return () => {
            try {
                socketClient.off('log_message')
            } catch (e) {
                console.error('Info Page-> Could not off log_message')
            }
        }
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} sm={7}>
                <InfoParameters oxinId={oxinId} />
            </Grid>
            <Grid item xs={12} sm={5} sx={{ pl: { xs: 0, sm: 4 } }}>
                <InfoLogs oxinId={oxinId} setLogsReady={setLogsReady} />
            </Grid>
        </Grid>
    )
}

const mapKey = {
    gps: () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '3rem',
            }}
        >
            <Badge color="secondary" badgeContent=" "></Badge>
            <Badge color="secondary" badgeContent=" "></Badge>
        </Box>
    ),
}

const InfoParameters = ({ oxinId }) => {
    const { data: robotMessage, isSuccess: isLastDataSuccess } = useOxinLastReportData(oxinId, {
        refetchInterval: 2000,
    })
    const report = isLastDataSuccess ? transformParametersInfo({ telemetry: robotMessage }) : false
    const info = isLastDataSuccess ? Object.keys(report) : []

    return (
        <Stack direction="column">
            <Typography variant="h6" sx={{ pt: 1 }}>
                Current Parameters -{' '}
                {isLastDataSuccess && robotMessage?.time && (
                    <>
                        <FontAwesomeIcon icon={faRotate} className="fa-regular" />{' '}
                        {new Date(Number(robotMessage?.time)).toLocaleTimeString()}{' '}
                    </>
                )}
            </Typography>
            <div style={{ padding: '1rem' }} className="info-parameters">
                {isLastDataSuccess ? (
                    info.map((title) => (
                        <div
                            key={`info-parameter-${title}`}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="overline">
                                {title.replace(/([a-z])([A-Z])/g, '$1 $2')}
                            </Typography>
                            {mapKey[title] ? (
                                mapKey[title]({ value: report[title], ...report })
                            ) : (
                                <Typography variant="button" display="inline-block" gutterBottom>
                                    {report[title] || '-'}
                                </Typography>
                            )}
                        </div>
                    ))
                ) : (
                    <SimpleLoading />
                )}
            </div>
        </Stack>
    )
}

const InfoLogs = ({ oxinId, setLogsReady }) => {
    const { isSuccess, data: lastLogs } = useOxinLastLogs(oxinId, { staleTime: 1000 * 60 * 60 })

    useEffect(() => {
        const element = document.getElementById(loggerDivId)
        if (element) element.scrollTop = element.scrollHeight
        console.debug('InfoLogs', lastLogs, isSuccess)
        if (isSuccess) {
            setLogsReady(true)
        }
    }, [lastLogs])

    return (
        <Stack direction="column" sx={{ height: 'auto', minHeight: '-webkit-fill-available' }}>
            <Typography variant="h6" sx={{ pt: 1 }}>
                Logs
            </Typography>
            <Box
                id={loggerDivId}
                className="info-logger"
                sx={{
                    fontSize: 'small',
                    bgcolor: 'var(--gray-background)',
                    overflowX: 'auto',
                }}
            >
                {!isSuccess ? (
                    <SimpleLoading />
                ) : (
                    lastLogs?.map((item, index) => (
                        <LogItem key={`log-item-${index}`} item={item} />
                    ))
                )}
            </Box>
        </Stack>
    )
}

const LogItem = ({ item }) => (
    <div className={`log  ${item.type || null}`}>
        <div>{new Date(item.time).toLocaleTimeString()}</div>
        <div>{item.message}</div>
    </div>
)

const SimpleLoading = () => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: ' -webkit-fill-available',
        }}
    >
        <CircularProgress size="5rem" />
    </Box>
)
