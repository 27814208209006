import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import styles from './LoadingCircularProgress.module.scss'

export const LoadingCircularProgress = () => {
    return (
        <div className={styles.root}>
            <Backdrop
                open={true}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <div className={styles.loading}>
                    <CircularProgress size="5rem" />
                </div>
            </Backdrop>
        </div>
    )
}
