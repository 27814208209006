import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import axiosApiInstance from '../api/config/axios'
import { Auth } from 'aws-amplify'

const getUser = async (): Promise<{}> => {
    //TODO add try/catch
    const { attributes } = await Auth.currentAuthenticatedUser()
    const { data } = await axiosApiInstance.get(`/user/${attributes.email}/`)
    return data
}

export const useUserInitDataUser = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    useEffect(() => {
        getUser()
            .then((data) => {
                setData(data)
                setIsLoading(false)
            })
            .catch((e) => {
                throw new Error(e)
            })
    }, [])
    return { data, isLoading }
}

export const useUser = () => {
    const data = useQuery(['user'], getUser, {
        staleTime: 1000 * 60 * 60,
        initialData: {
            oxin: [],
        },
        // placeholderData: [],
    })

    return data
}
