import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Switch } from '../../ui-components'

const Blower = (props) => {
    return (
        <Grid container sx={{ width: '100%' }}>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { sm: 2, xs: 1 },
                    py: { sm: 2, xs: 1 },
                }}
            >
                <Switch
                    id="blower_control"
                    label="BLOWER"
                    config={{ min: 0, max: 100 }}
                    className="smaller"
                    options={[
                        <>
                            <FontAwesomeIcon icon={faPowerOff} size="sm" /> OFF
                        </>,
                        <>
                            <FontAwesomeIcon icon={faPlay} size="sm" /> ON
                        </>,
                    ]}
                />
                <Switch
                    id="left_arm_distance_control"
                    label="L ARM"
                    type="range"
                    className="smaller"
                    config={{ min: 0, max: 400, fix: 0 }}
                />
            </Grid>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { sm: 2, xs: 1 },
                    py: { sm: 2, xs: 1 },
                }}
            >
                <Switch id="head_control" className="smaller" label="HEAD" type="percent" />
            </Grid>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { sm: 2, xs: 1 },
                    py: { sm: 2, xs: 1 },
                }}
            >
                <Switch
                    id="blower_pressure_control"
                    label="PRESSURE"
                    type="decimal"
                    className="smaller"
                    config={{ min: 50, max: 100, fix: 2, threshold: 1, start: 40 }}
                />
                <Switch
                    id="right_arm_distance_control"
                    label="R ARM"
                    type="range"
                    className="smaller"
                    config={{ min: 0, max: 400, fix: 0 }}
                />
            </Grid>
        </Grid>
    )
}

Blower.propTypes = {}

export default Blower
