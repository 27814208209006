import React from 'react'
import styles from './Loading.module.scss'

export const Loading = () => {
    return (
        <div className={styles.root}>
            <div className={styles.spinner}>
                <div className={styles.circle}>
                    <div className={styles.inner}></div>
                </div>
                <div className={styles.circle}>
                    <div className={styles.inner}></div>
                </div>
                <div className={styles.circle}>
                    <div className={styles.inner}></div>
                </div>
                <div className={styles.circle}>
                    <div className={styles.inner}></div>
                </div>
                <div className={styles.circle}>
                    <div className={styles.inner}></div>
                </div>
            </div>
            <a className={styles.title}>OXIN DRIVER</a>
        </div>
    )
}
