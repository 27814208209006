import React from 'react'

import {
    faCircleDot,
    faPlay,
    faLocationDot,
    faClock,
    faCircleCheck,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import SvgIcon from '@mui/material/SvgIcon'
import Chip from '@mui/material/Chip'

import BorderLinearProgress from './BorderLinearProgress'
import styles from './styles.module.scss'
import { useMissionStats } from '../../hooks'
import { transformMissionData } from '../../utils'

import {
    SpeedIconSVG,
    DistanceIconSVG,
    WaypointIconSVG,
    DistanceInProgressIconSVG,
} from '../../assets/icons'

export const MissionItem = ({
    oxinId,
    id,
    name,
    color,
    inprogress = false,
    selected = false,
    enabledToLoad = false,
    onClick = () => null,
    _handlerLoadMission = () => null,
    missionRunning = {},
}) => {
    //const enabledToLoad = true
    const { data: stats, isSuccess: isSuccessGetStats } = useMissionStats(id)
    const handleClick = () => {
        onClick(id)
    }
    const distance = isSuccessGetStats ? (
        Math.ceil(stats.distance)
    ) : (
        <FontAwesomeIcon icon={faSpinner} spin />
    )
    const points = isSuccessGetStats ? stats.point_count : <FontAwesomeIcon icon={faSpinner} spin />
    const progress = 40
    React.useEffect(() => {}, [])
    return (
        <Card
            sx={{ backgroundColor: inprogress ? '#defce47d' : 'white' }}
            onClick={handleClick}
            className={selected ? styles['card-selected'] : styles['card']}
        >
            <CardActionArea>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ p: 1, height: '100%', pb: '0.8rem!important' }}>
                        <Box sx={{ flexDirection: 'row', width: '100%' }}>
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                color="text.secondary"
                                style={{ display: 'inline-block', color: '#00404d' }}
                            >
                                <FontAwesomeIcon icon={faCircleDot} style={{ color: color }} />{' '}
                                {name}
                            </Typography>
                            {inprogress && (
                                <>
                                    <ProgressDescription />
                                    <MissionProgressDescription
                                        distance={distance}
                                        points={points}
                                        missionRunning={missionRunning}
                                    />
                                </>
                            )}
                            {!inprogress && (
                                <MissionDescription distance={distance} points={points} />
                            )}

                            {!inprogress && selected && enabledToLoad && (
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ mt: '0.7rem' }}
                                    onClick={() => {
                                        _handlerLoadMission({ id, name, maxWaypoints: points })
                                    }}
                                >
                                    LOAD MISSION
                                </Button>
                            )}
                        </Box>
                    </CardContent>
                </Box>
            </CardActionArea>
        </Card>
    )
}

const MissionDescription = ({ distance, points }) => {
    return (
        <Box
            variant="soft"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                mt: 1,
                pt: 1,
                justifyContent: 'space-around',
                alignItems: 'center',
                borderTop: '1px solid',
                borderColor: 'divider',
                '& hr': {
                    mx: 0.5,
                },
            }}
        >
            <LabelSvgIcon label="Distance">
                <DistanceIconSVG />
            </LabelSvgIcon>
            <Typography variant="button">{distance} KM</Typography>
            <Divider orientation="vertical" />
            <Typography variant="overline">
                <FontAwesomeIcon icon={faLocationDot} /> Points
            </Typography>
            <Typography variant="button">{points}</Typography>
        </Box>
    )
}

const styleRowInfo = {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    mt: 1,
    pt: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid',
    borderColor: 'divider',
    '& hr': {
        mx: 0.5,
    },
}
const MissionProgressDescription = ({
    distance = '-',
    missionRunning = {},
    operations = [],
    autoDrive = '2:30 H',
}) => {
    const { points, nextPoint, speed, mower, sprayer, arms } = transformMissionData({
        telemetry: missionRunning,
    })
    console.debug('MissionProgressDescription, missionRunning', missionRunning)
    return (
        <Box sx={{ gap: 1, display: 'inline-flex', flexDirection: 'column' }}>
            <Box variant="soft" sx={styleRowInfo}>
                <LabelSvgIcon label="Distance">
                    <DistanceInProgressIconSVG />
                </LabelSvgIcon>
                <Typography variant="button">{distance} KM</Typography>
                <Divider orientation="vertical" />
                <LabelSvgIcon label="Next WP">
                    <WaypointIconSVG />
                </LabelSvgIcon>
                <Typography variant="button">{`${nextPoint}/${points}`}</Typography>
            </Box>

            <Box variant="soft" sx={styleRowInfo}>
                <LabelSvgIcon label="Speed">
                    <SpeedIconSVG />
                </LabelSvgIcon>
                <Typography variant="button">{speed}</Typography>
                <Divider orientation="vertical" />
                <LabelFontAwesomeIcon label="Auto Drive" icon={faClock} />
                <Typography variant="button">{autoDrive}</Typography>
            </Box>
            <Box variant="soft" sx={styleRowInfo}>
                <Typography variant="overline"> Operations</Typography>
                {mower && (
                    <Chip
                        variant="outlined"
                        color="success"
                        size="small"
                        label="MOWER"
                        deleteIcon={<FontAwesomeIcon icon={faCircleCheck} />}
                        onDelete={() => {}}
                    />
                )}
                {arms && arms != 'unknown' && (
                    <Chip
                        variant="outlined"
                        color="warning"
                        size="small"
                        label="ARMS"
                        deleteIcon={<FontAwesomeIcon icon={faCircleCheck} />}
                        onDelete={() => {}}
                    />
                )}
                {sprayer && sprayer != 'unknown' && (
                    <Chip
                        variant="outlined"
                        color="info"
                        size="small"
                        label="SPRAYER"
                        deleteIcon={<FontAwesomeIcon icon={faCircleCheck} />}
                        onDelete={() => {}}
                    />
                )}
            </Box>
        </Box>
    )
}
const ProgressDescription = ({ progress = 30 }) => {
    return (
        <div
            style={{
                height: '2.5rem',
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: '8px',
            }}
        >
            <div style={{ display: 'contents' }}>
                <BorderLinearProgress variant="determinate" value={progress} />
                <span style={{ fontSize: 'small', paddingLeft: '4px' }}>{progress}%</span>
            </div>
            <Chip
                label="Running"
                color="success"
                size="small"
                sx={{ ml: 2, p: '0.5rem' }}
                icon={<FontAwesomeIcon icon={faPlay} style={{ color: 'white' }} />}
            />
        </div>
    )
}

export const LabelSvgIcon = ({ children, label, sx = {} }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', ...sx }}>
        <SvgIcon>{children}</SvgIcon>
        <Typography variant="overline"> {label}</Typography>
    </div>
)

export const LabelFontAwesomeIcon = ({ icon, label, sx = {} }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', ...sx }}>
        <FontAwesomeIcon icon={icon} />
        <Typography variant="overline"> {label}</Typography>
    </div>
)
