import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

function CircularProgressWithLabel(
    props: CircularProgressProps & {
        value: number
        colorv2: string
        description?: React.ReactNode
        ariaLabel?: string
    }
) {
    //const color =

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ position: 'relative', minWidth: props.size, minHeight: props.size }}>
                <div style={{ position: 'absolute' }}>
                    <CircularProgress
                        variant="determinate"
                        aria-label="Decoration circle"
                        sx={{
                            color: 'white', //(theme) =>
                            //theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
                        }}
                        {...props}
                        value={100}
                    />
                </div>
                <div style={{ position: 'absolute' }}>
                    <CircularProgress
                        aria-label={props.ariaLabel}
                        variant="determinate"
                        {...props}
                        sx={{
                            color: props.colorv2,
                        }}
                    />
                </div>
            </Box>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {props.description ? props.description : `${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

export const CircularProgressBar = ({
    value = 0,
    description,
    size = '5rem',
    colorv2,
    ariaLabel,
}: {
    value: number
    description?: React.ReactNode
    size: string | number
    colorv2: string
    ariaLabel?: string
}) => {
    const percentage = value
    const [progressPercentage, setProgressPercentage] = useState(0)
    const [beforePercentageValue, setBeforePercentageValue] = useState(0)
    const interval = 20
    useEffect(() => {
        setProgressPercentage(0)
    }, [value])

    // BeforePercentage if the value is changening
    useEffect(() => {
        const untilPercentage = beforePercentageValue ? beforePercentageValue : percentage
        //console.log(untilPercentage, beforePercentageValue, percentage)
        const timeout = setTimeout(() => {
            if (progressPercentage < untilPercentage) {
                setProgressPercentage(progressPercentage + untilPercentage)
            }
        }, interval)

        setBeforePercentageValue(percentage)
        return () => {
            clearTimeout(timeout)
        }
    }, [progressPercentage, value])

    return (
        <CircularProgressWithLabel
            description={description}
            value={progressPercentage}
            thickness={4}
            size={size}
            colorv2={colorv2}
            ariaLabel={ariaLabel}
        />
    )
}
