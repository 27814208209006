/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react'

import { faBell, faGears, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { StatusAvt } from '..'
import useNavButtons from '../../hooks/useNavButtons'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

const DrawerNav = ({
    drawerWidth,
    avt: { disconnected, offline, locked, automatic, notifications },
    selected,
    handleDrawerToggle,
}: {
    drawerWidth: number
    avt: { name: string }
    selected: string
}) => {
    const { getNavButtons } = useNavButtons()
    const { name } = useOxinSelected()

    const buttons = getNavButtons({ name, locked, offline, automatic, disconnected })
    const navParams = useParams()
    return (
        <div>
            <Toolbar>
                <StatusAvt disconnected={disconnected} offline={offline} />
                <NavLink to={`/`} style={{ lineHeight: 1 }}>
                    <Typography
                        sx={{ ml: 2, flex: 1, color: 'secondary' }}
                        variant={'oxin-title' as any}
                    >
                        {name}
                    </Typography>
                </NavLink>
            </Toolbar>
            <Divider />
            <List>
                {buttons.map((propsNav) => (
                    <ListItemNav {...propsNav} handleDrawerToggle={handleDrawerToggle} />
                ))}
                <Divider />
                <ListItemNav
                    title={'Notifications'}
                    to="notifications"
                    icon={faBell}
                    handleDrawerToggle={handleDrawerToggle}
                    secondaryAction={
                        notifications ? (
                            <IconButton edge="end" aria-label="delete">
                                <Chip label={notifications} color="primary" />
                            </IconButton>
                        ) : undefined
                    }
                />
            </List>
            <Divider />
            <List sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                {footerButtons.map((propsNav) => (
                    <ListItemNav {...propsNav} handleDrawerToggle={handleDrawerToggle} />
                ))}
            </List>
        </div>
    )
}

const ListItemNav = ({
    title,
    icon,
    to,
    handleDrawerToggle,
    secondaryAction,
}: {
    title: string
    icon: IconProp
    to: string
    handleDrawerToggle: React.MouseEventHandler<HTMLAnchorElement>
    secondaryAction?: JSX.Element
}) => (
    <ListItem
        key={title}
        disablePadding
        sx={{ color: 'primary' }}
        secondaryAction={secondaryAction}
    >
        <NavLink
            to={`/${to}`}
            key={`${to}-nav-button`}
            style={{
                color: 'inherit',
                width: '100%',
            }}
            onClick={handleDrawerToggle}
        >
            <ListItemButton>
                <ListItemIcon>
                    <FontAwesomeIcon icon={icon} />
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </NavLink>
    </ListItem>
)

const footerButtons = [
    { title: 'Settings', to: 'settings', icon: faGears, key: 'settings' },
    { title: 'Logout', to: 'settings', icon: faRightToBracket, key: 'logout' },
]
export default DrawerNav
