import React, { useState } from 'react'

import {
    faMapLocation,
    faVideo,
    faCircleInfo,
    faGamepad,
    faLink,
    faLock,
    faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { NavLink } from 'react-router-dom'

import { SignalOffSVG } from '../../assets/icons'
import useNavButtons from '../../hooks/useNavButtons'
import { MainButtonActionV2 as MainButtonAction } from '../../ui-components'
import { ChoiceCard } from '../../ui-components'
import { StatusAvt } from '../StatusAvt'
import { AlertNavButton } from '../Buttons/Buttons'
import { useBreakpoint, useSocketTelemetry } from '../../hooks'

const COMPONENTS_SIZES = {
    card: {
        default: {
            size: '12rem',
        },
        xl: {
            size: '14rem',
        },
        lg: {
            size: '12rem',
        },
    },
}
const getComponentSizes = (size) => {
    return {
        card: COMPONENTS_SIZES.card[size] || COMPONENTS_SIZES.card.default,
    }
}
export const MainCardSelected = ({
    id,
    name,
    notifications,
    maintenance,
    data: initialTelemetryData = {},
    onClick,
}: {
    id: string
    name: string
    notifications?: number
    data: {}
}) => {
    const { getBreakPointName } = useBreakpoint()
    const componentSizes = getComponentSizes(getBreakPointName())
    const { getNavButtons } = useNavButtons()
    const { data, isLoading } = useSocketTelemetry(name, initialTelemetryData)

    const buttons = getNavButtons({
        userIsOwner: data.userIsOwner,
        name,
        offline: data.offline,
        automatic: data.isAutomatic,
    })
    return (
        <ChoiceCard
            selected={true}
            data-testid={`choice-card-selected-${id}`}
            maintenance={maintenance}
            onClick={!data.disconnected ? onClick : undefined}
        >
            <Grid
                container
                sx={{ minHeight: componentSizes.card.size, width: 1, mx: 0, padding: '1rem' }}
            >
                <Grid {...{ xs: 12, sm: 4, md: 3, lg: 3 }}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            '& > span ': { marginTop: '0.5rem!important' },
                        }}
                    >
                        <StatusAvt disconnected={data.disconnected} offline={data.offline} />
                        <Typography
                            variant={'oxin-subtitle-online' as any}
                            component="h3"
                            color={'var(--avt-oxin);'}
                        >
                            {name}
                        </Typography>

                        <Typography
                            variant="overline"
                            component="span"
                            sx={{ lineHeight: 'inherit' }}
                        >
                            {data.vemd}
                        </Typography>
                        <Typography
                            variant="overline"
                            component="span"
                            sx={{ lineHeight: 'inherit' }}
                        >
                            {data.locked ? 'Locked' : ''}
                        </Typography>
                        <Typography
                            variant="overline"
                            component="span"
                            sx={{
                                lineHeight: 'inherit',
                                color: 'secondary.main',
                                fontSize: 'x-small',
                                color: 'rgb(67 67 67 / 89%)',
                                paddingTop: '0.4rem',
                                textAlign: 'center',
                            }}
                        >
                            {data.time ? 'Last Update\n' + data.timeText : '-'}
                        </Typography>
                        {notifications ? <AlertNavButton notifications={notifications} /> : <></>}
                    </Stack>
                </Grid>
                <Grid container {...{ xs: 12, sm: 8, md: 9, lg: 9 }}>
                    {buttons
                        .filter((button) => !button.onlyToolbar)
                        .map(({ title, to, icon }: { title: string; to: string }) => (
                            <Grid
                                {...{ xs: 6 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                key={`card-button-${to}`}
                            >
                                <MainButtonAction
                                    icon={icon}
                                    title={title}
                                    key={`${title}-button`}
                                    to={to}
                                ></MainButtonAction>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </ChoiceCard>
    )
}
