/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable padded-blocks */
import axios from 'axios'
import { Auth } from 'aws-amplify'
import AppConfig from '../../constans'

const axiosApiInstance = axios.create({
    baseURL: `${AppConfig.apiUrl}/api`,
})

axiosApiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response && error.response.status === 401) {
            await Auth.signOut()
        }

        return error
    }
)

// Add a request interceptor
axiosApiInstance.interceptors.request.use(async function (config) {
    const authdata = await Auth.currentSession()
    config.headers.Authorization = `Bearer ${authdata.getIdToken().getJwtToken()}`
    return config
})

export default axiosApiInstance
