// Heartbeat send a message to the server every 500ms
import { sendLog } from '../utils'
const REACT_APP_HEARTBEAT_REFRESH = process.env.REACT_APP_HEARTBEAT_REFRESH
export class Heartbeat {
    #user
    #oxinId
    #socket
    #heartbeatTimer
    #active

    // set the veid and topic
    constructor(oxinId, socket) {
        this.#oxinId = oxinId
        this.#user = 'GlenyRodriguez'
        this.#socket = socket
        this.#active = false
    }

    #sendHeartBeat(stop = false) {
        const veid = this.#oxinId
        const payload = {
            oxinId: veid,
            stop,
        }
        sendLog('heartbeat', payload)
        console.log('SEND HEARTBEAT-------', payload)
        this.#socket.emit('heartbeat', payload)
    }

    #refreshTimer() {
        if (this.#heartbeatTimer) return
        this.#heartbeatTimer = setInterval(() => {
            if (this.#active) this.#sendHeartBeat()
            else this.stop()
        }, 300)
        if (!window.$__intervals) window.$__intervals = []
        window.$__intervals.push(this.#heartbeatTimer)
    }

    start() {
        if (!this.#active) {
            sendLog('heartbeat', `START ${this.#oxinId}`)
            this.#sendHeartBeat()
            this.#active = true
            this.#refreshTimer()
        } else {
            sendLog('heartbeat', `WAS STARTED ${this.#oxinId} - NOT START AGAIN`)
        }
    }

    stop() {
        if (this.#heartbeatTimer) {
            clearInterval(this.#heartbeatTimer)
            this.#heartbeatTimer = undefined
            sendLog('heartbeat', `STOP ${this.#oxinId} - CLEAR INTERVAL`)
        } else {
            sendLog('heartbeat', `COULD NOT STOP ${this.#oxinId} - DONT FOUND INTERVAL ID`)
        }
        this.#sendHeartBeat(true)
        this.#active = false
    }
}
