import { Heartbeat } from './Heartbeat.js'
import { sendLog } from '../utils'
export class Vehicle {
    #heartbeat
    #lastTeleUpdate = 0
    #sound = 0
    #socket

    constructor(oxinId, socket) {
        this.veid = oxinId
        this.#socket = socket
        this.maintenanceMode = false
        this.checkMaintenanceMode()
        this.lastMaintenanceModeCheck = Date.now()
        this.#heartbeat = new Heartbeat(this.veid, socket)
        this.#sound = new Audio('/public/stop_sound.mp3')
        this.engineMode = 0
        this.sendLock = this.sendLock.bind(this)
        this.sendUnlock = this.sendUnlock.bind(this)
        this.sendArm = this.sendArm.bind(this)
        this.sendDisarm = this.sendDisarm.bind(this)
    }

    sendLock() {
        sendLog('vehicle', `sendLock ${this.veid}`, this.#heartbeat)
        this.#heartbeat.start()
    }
    sendUnlock() {
        console.warn('vehicle', `sendUnlock ${this.veid}`, this.#heartbeat)
        this.#heartbeat.stop()
    }

    sendArm() {
        sendLog('vehicle', `sendArm ${this.veid}`)
        this.#socket.emit('arm', { oxinId: this.veid })
    }
    sendDisarm() {
        console.warn('vehicle', `sendDisarm ${this.veid}`)
        this.#socket.emit('disarm', { oxinId: this.veid })
    }

    checkMaintenanceMode() {
        if (this.lastMaintenanceModeCheck > Date.now() - 30000) {
            return
        }
        sendLog('vehicle', `checking maintenance mode for ${this.veid}`)
        /*fetch(`/OxinFleet/api/get-in-maintenance/${this.veid}/`)
            .then((response) => response.json())
            .then((data) => {
                this.maintenanceMode = data.in_maintenance
            })
            .catch((err) => {
                console.log(err)
            })*/
        this.lastMaintenanceModeCheck = Date.now()
    }

    disconnect() {
        this.#heartbeat.stop()
        sendLog('vehicle', `disconnect ${this.veid}`)
    }
}
