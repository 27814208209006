import React, { useEffect, useRef } from 'react'
import { TwoPositionalSwitch, HitchControl, PercentageSwitch, Range, DecimalSwitch } from '../../js'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSocket } from '../../contexts/SocketContext'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

export const Switch = ({
    id,
    label,
    config = { min: 0, max: 1 },
    options = [],
    type = 'simple',
    className,
    value,
    reset,
    ...props
}) => {
    const { name: oxinId } = useOxinSelected()
    const socketClient = useSocket()
    const inputSwitchRef = useRef()
    useEffect(() => {
        let inputSwitch
        switch (type) {
            case 'hitch':
                inputSwitch = new HitchControl(id, config, socketClient, oxinId)
                break
            case 'percent':
                inputSwitch = new PercentageSwitch(id, config, socketClient, oxinId)
                break
            case 'range':
                inputSwitch = new Range(id, config, socketClient, oxinId)
                break
            case 'decimal':
                inputSwitch = new DecimalSwitch(id, config, socketClient, oxinId)
                break
            default:
                inputSwitch = new TwoPositionalSwitch(id, config, socketClient, oxinId)
                break
        }
        inputSwitchRef.current = inputSwitch
        return () => {
            console.log('DESTROY SWITCH ID', id)
            inputSwitch.destroy()
            inputSwitchRef.current = null
        }
    }, [])

    useEffect(() => {
        //console.log('RESET--------', reset, inputSwitchRef)
        if (reset && inputSwitchRef.current.reset) inputSwitchRef.current.reset()
    }, [reset])
    return (
        <Box {...props} className={`controls_window__driving_control ${className}`}>
            <Box
                display="flex"
                justifyContent="space-between"
                pl={2}
                pr={2}
                sx={{ textAlign: 'center' }}
            >
                {options.map((value, index) => (
                    <Typography key={`${index}-${value}`} variant={'overline'} component="span">
                        {value}
                    </Typography>
                ))}
                {['percent', 'range', 'decimal'].indexOf(type) !== -1 && (
                    <Typography
                        id={`switch_label_${id}_value`}
                        variant={'overline'}
                        component="span"
                        sx={{ width: '100%' }}
                    >
                        {'-'}
                    </Typography>
                )}
            </Box>
            <div className="switch_draggable" id={`switch_draggable_${id}`}>
                <div className="switch_inner_wrapper" id={`switch_inner_${id}_wrapper`}>
                    <div
                        className="switch_inner"
                        id={`switch_inner_${id}`}
                        data-state="0"
                        data-action={id}
                    >
                        {label}
                    </div>
                </div>
            </div>
        </Box>
    )
}
