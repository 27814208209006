import Publisher from './Publisher'

export class OneShotButton extends Publisher {
    constructor(containerId, { stateValue = 0 }, socketClient, oxinId) {
        super(socketClient, oxinId)
        this.$el = document.querySelector('#' + containerId)
        this.state = Number(stateValue)
        this.functionClick = this.sendCommand.bind(this)
        this.$el.addEventListener('click', this.functionClick)
    }

    sendCommand() {
        this.publishCommand()
    }
    destroy() {
        this.$el.removeEventListener('click', this.functionClick)
    }
}
