import React from 'react'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

import { styled } from '@mui/material/styles'

const DefaultLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: '80%',
    minWidth: '5rem',
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}))

const BorderLinearProgress = ({ ...props }) => <DefaultLinearProgress {...props} />

export default BorderLinearProgress
