import React, { useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import classNames from 'classnames'
import { TrackController } from './TrackUtils'
import styles from './Drive.module.scss'
import { ContainerComponent } from './index'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'
import { useSocket } from '../../contexts/SocketContext'

const Controller = ({ id }) => (
    <div className="draggable_container" id={`${id}_container`}>
        <div
            className="col h-25 align-self-center"
            id={`${id}_control`}
            data-action="track_drive"
        />
    </div>
)

export const DriveController = ({ size, children }) => {
    const socketClient = useSocket()
    const { name } = useOxinSelected()
    const oxinId = name.toLowerCase()
    console.log('DriveController->oxin', oxinId)
    useEffect(() => {
        const _handlerMoveController = (payload) => {
            const data = { oxinId, ...payload }
            console.log('Drive track----- ', payload)
            socketClient.emit('drive', data)
        }
        new TrackController('left_track_control', 'right_track_control', _handlerMoveController)
        return () => {}
    }, [])

    return children ? (
        <ContainerComponent size={size}>
            <Grid xs={3} sx={{ height: 'calc(100vh - 6rem )' }}>
                <Controller id="left_track" />
            </Grid>
            {children}
            <Grid xs={3} sx={{ height: 'calc(100vh - 6rem )' }}>
                <Controller id="right_track" />
            </Grid>
        </ContainerComponent>
    ) : (
        <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ height: 'calc(80vh - 4.5em )', width: '50%', padding: '1rem' }}>
                <Controller id="left_track" />
            </div>
            <div style={{ height: 'calc(80vh - 4.5rem )', width: '50%', padding: '1rem' }}>
                <Controller id="right_track" />
            </div>
        </div>
    )
}
