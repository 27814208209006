import React, { createContext, useContext, useState } from 'react'
import { Vehicle } from '../js/Vehicle'

export const VehiclesContext = createContext()

export function VehiclesProvider({ children, ...props }) {
    const [vehicles, setVehicles] = useState(() => {
        const vehicles = {}
        const socket = props.value.socket
        props.value.oxins.forEach((v) => {
            const vehicle = new Vehicle(v.name, socket)
            vehicles[v.name] = vehicle
        })
        return vehicles
    })

    return <VehiclesContext.Provider value={{ vehicles }}>{children}</VehiclesContext.Provider>
}

export const useVehicles = () => useContext(VehiclesContext)
