import { useQuery } from '@tanstack/react-query'
import Oxin from '../api/endpoints/Oxin'

export const useMissions = (oxinId) => {
    try {
        const data = useQuery({
            queryKey: [`oxin-missions`, oxinId],
            queryFn: async () => {
                // myParameter can be undefined, skip call if that's the case
                if (!oxinId) return
                const data = await Oxin.getOxin(oxinId)
                return data?.data?.missions || []
            },
            staleTime: 1000 * 60 * 5,
        })
        return data
    } catch (error) {
        console.log('src/hooks/useMissions.tsx error', error)
        return {}
    }
}
