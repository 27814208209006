import React from 'react'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { Authenticator, Image, View } from '@aws-amplify/ui-react'
import { Loading } from './ui-components'
import { InternalErrorServer } from './views/Errors'
import { ErrorBoundary } from 'react-error-boundary'

import './App.scss'

import '@aws-amplify/ui-react/styles.css'

Amplify.configure(awsExports)

import AnimaApp from './App.tsx'

import logo from './logo.png' // Tell webpack this JS file uses this image

import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.oxin.nz/">
                OXIN
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

const components = {
    Header() {
        return (
            <View textAlign="center" style={{ padding: '1rem' }}>
                <Image
                    alt="Oxin driver logo"
                    src={logo}
                    style={{
                        maxWidth: '8rem',
                    }}
                />
            </View>
        )
    },
    Footer() {
        return (
            <View textAlign="center" style={{ padding: '1rem' }}>
                <Copyright />
            </View>
        )
    },
    SignIn: {
        Header() {
            return (
                <Typography textAlign="center" component="h1" variant="h5">
                    Sign in
                </Typography>
            )
        },
    },
    ResetPassword: {
        Header() {
            return (
                <Typography textAlign="center" component="h1" variant="h5">
                    Reset Password
                </Typography>
            )
        },
    },
}

export default function App() {
    return (
        <Authenticator className={'login'} hideSignUp={true} components={components}>
            {({ signOut, user }) => (
                <main style={{ height: '100%' }}>
                    {user?.attributes ? (
                        <ErrorBoundary fallback={<InternalErrorServer />}>
                            <AnimaApp user={user} signOut={signOut} />
                        </ErrorBoundary>
                    ) : (
                        <Loading />
                    )}
                </main>
            )}
        </Authenticator>
    )
}
