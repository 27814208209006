import React, { useState, useEffect, useRef } from 'react'
import L from 'leaflet'
import 'leaflet-polylinedecorator'
import { toast } from 'react-toastify'
import {
    Marker,
    Popup,
    GeoJSON,
    FeatureGroup,
    CircleMarker,
    useMap,
    LayersControl,
} from 'react-leaflet'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { RobotIconSVG } from '../../assets/icons'
import { Map, LoadingCircularProgress } from '../../ui-components'
import { useMission, useOxinLocation, useGeolocation } from '../../hooks'
import vehicleIcon from './vehicle.png'

export const MissionMap = ({ oxinId, missionIdSelected }) => {
    const [map, setMap] = useState(null)
    const { loading, error, data: geolocationData } = useGeolocation()
    const [initial, setInitial] = useState(false)
    const [refreshLocation, setRefreshLocation] = useState(true)

    const { isSuccess: oxinLocationIsSuccess, data: oxinLocation } = useOxinLocation(oxinId, {
        enabled: refreshLocation,
        refetchInterval: 2000,
    })
    const { isSuccess: missionIsSuccess, data: mission } = useMission(missionIdSelected)

    const [coordinates, setCoordinates] = useState(() => {
        const defaultCoordinates = [-41.484618307926795, 173.87285879284664]
        return oxinLocationIsSuccess && oxinLocation.length ? oxinLocation : defaultCoordinates
    })

    const globalLoading = loading || !missionIsSuccess

    useEffect(() => {
        if (missionIsSuccess && mission?.waypoints?.length && map?.target) {
            const lastIndex = mission.waypoints.length - 1
            const coord = [mission.waypoints[lastIndex].lat, mission.waypoints[lastIndex].lng]
            setCoordinates(coord)
            map?.target.panTo(coord, 15)
        }
    }, [missionIdSelected, missionIsSuccess, mission, map])

    useEffect(() => {
        if (!initial && oxinLocationIsSuccess && oxinLocation.length && map?.target) {
            setInitial(true)
            setCoordinates(oxinLocation)
            map?.target.panTo(oxinLocation, 15)
        }
    }, [oxinLocationIsSuccess, oxinLocation, map])

    return (
        <Box sx={{ bgcolor: '#cfe8fc', height: '-webkit-fill-available' }} id="mission_page">
            <Map fullHeight={true} latlng={coordinates} setMap={setMap}>
                <LocateOxinButtonControl oxinId={oxinId} map={map} />
                <LocateMissionSelectedButtonControl mission={mission} map={map} />
                {oxinLocationIsSuccess &&
                    oxinLocation.length &&
                    renderOxinLocation(oxinLocationIsSuccess, oxinLocation)}

                {globalLoading ? (
                    <LoadingCircularProgress />
                ) : mission?.waypoints ? (
                    <LayersControl position="bottomleft">
                        {renderExternalBoundaries(mission?.external_boundary)}
                        {renderObstacles(mission?.internal_boundaries)}
                        {renderMissionPath(mission?.waypoints)}
                        {renderWaypoints(mission?.waypoints)}
                    </LayersControl>
                ) : (
                    <></>
                )}
            </Map>
        </Box>
    )
}

const renderExternalBoundaries = (externalBoundary) => (
    <LayersControl.Overlay checked name="Boundaries">
        <FeatureGroup id="external_boundaries">
            <GeoJSON data={externalBoundary} pathOptions={pathOptions} />
        </FeatureGroup>
    </LayersControl.Overlay>
)

const renderObstacles = (obstacles) => (
    <LayersControl.Overlay checked name="Obstacles">
        <FeatureGroup id="internal_boundaries">
            {obstacles.map((polygon, index) => (
                <GeoJSON
                    data={polygon}
                    key={`mission-obstacle-${index}`}
                    pathOptions={{
                        color: 'red',
                        fill: false,
                        weight: 4,
                    }}
                />
            ))}
        </FeatureGroup>
    </LayersControl.Overlay>
)

const renderMissionPath = (waypoints) => {
    if (!waypoints) return null
    const pathPoints = waypoints.map((wp) => [wp.lat, wp.lng])
    return (
        <LayersControl.Overlay checked name="Mission Path" id="mission_path">
            <FeatureGroup>
                <PolylineDecorator patterns={arrow} polyline={pathPoints} />
            </FeatureGroup>
        </LayersControl.Overlay>
    )
}

const renderWaypoints = (waypoints) => (
    <LayersControl.Overlay name="Waypoints">
        <FeatureGroup id="waypoints">
            {waypoints.map((p, index) => (
                <Marker
                    key={`mission-waypoint-${index}`}
                    position={[p.lat, p.lng]}
                    icon={iconWaypoint({ waypointId: index + 1 })}
                />
            ))}
        </FeatureGroup>
    </LayersControl.Overlay>
)

const renderOxinLocation = (isSuccess, location) =>
    isSuccess && location.length ? <Marker position={location} icon={iconPerson}></Marker> : null

const LocateOxinButtonControl = ({ oxinId, map }) => {
    const [enabled, setEnabled] = useState(false)
    const {
        isSuccess,
        isFetching,
        data: oxinLocation,
        refetch,
    } = useOxinLocation(oxinId, {
        enabled,
    })

    useEffect(() => {
        if (isSuccess && map && enabled && !isFetching) {
            if (oxinLocation.length) {
                map?.target.panTo(oxinLocation, 18)
                setEnabled(false)
            } else {
                toast.error(`Robot ${oxinId} `)
            }
        }
    }, [isSuccess, oxinLocation, enabled, isFetching])

    return (
        <>
            {isFetching && enabled && <LoadingCircularProgress />}
            <div className="leaflet-control leaflet-bar map-legend leaflet-oxin-location">
                <Button
                    variant="outlined"
                    size="large"
                    onClick={() => {
                        setEnabled(true)
                        refetch()
                    }}
                >
                    <RobotIconSVG /> Locate
                </Button>
            </div>
        </>
    )
}

const LocateMissionSelectedButtonControl = ({ mission, map }) => {
    const [locate, setLocate] = useState()

    useEffect(() => {
        if (mission && mission?.waypoints?.length && map) {
            const waypoints = mission?.waypoints[0]
            const coord = [waypoints.lat, waypoints.lng]
            map?.target.panTo(coord, 18)
        }
    }, [locate])

    return mission?.waypoints ? (
        <div className="leaflet-control leaflet-bar map-legend leaflet-mission-location">
            <Button
                variant="outlined"
                size="large"
                onClick={() => {
                    setLocate(new Date().getTime())
                }}
            >
                <RobotIconSVG /> GO TO MISSION
            </Button>
        </div>
    ) : null
}

// Define constants
const iconPerson = new L.Icon({
    iconUrl: vehicleIcon,
    iconRetinaUrl: vehicleIcon,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: 'leaflet-div-icon',
})

const iconWaypoint = ({ waypointId }) =>
    L.divIcon({
        className: 'marker-white border-white',
        html: `<div class="marker-number" data-wp-id="${waypointId}">${waypointId}</div>`,
    })

const pathOptions = {
    color: 'white',
    fill: false,
    weight: 4,
}

const arrow = [
    {
        offset: '10%',
        repeat: '40%',
        color: 'lime',
        symbol: L.Symbol.arrowHead({
            pixelSize: 15,
            polygon: false,
            pathOptions: { stroke: true, color: 'lime' },
        }),
    },
]

function PolylineDecorator({ patterns, polyline = [] }) {
    const map = useMap()
    const polylinePoints = useRef()
    useEffect(() => {
        return () => {
            if (polylinePoints.current && map) {
                map.removeLayer(polylinePoints.current)
            }
        }
    }, [])
    useEffect(() => {
        if (!map || !polyline.length) return
        if (polylinePoints.current) {
            map.removeLayer(polylinePoints.current)
        }
        polylinePoints.current = L.layerGroup([
            L.polyline(polyline, { color: 'lime' }),
            L.polylineDecorator(polyline, {
                patterns,
            }),
        ])
        polylinePoints.current.addTo(map)
    }, [map])

    return null
}

function circleWithText2(latLng, txt, radius, borderWidth, circleClass) {
    var size = radius * 2
    var style =
        'style="width: ' +
        size +
        'px; height: ' +
        size +
        'px; border-width: ' +
        borderWidth +
        'px;"'
    var iconSize = size + borderWidth * 2
    var icon = L.divIcon({
        html: '<span class="' + 'circle ' + circleClass + '" ' + style + '>' + txt + '</span>',
        className: '',
        iconSize: [iconSize, iconSize],
    })
    var marker = L.marker(latLng, {
        icon: icon,
    })
    return marker
}
