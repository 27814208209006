import * as React from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import { ImplementTabs } from './Tabs'
import Box from '@mui/material/Box'

import {
    faPowerOff,
    faPlay,
    faArrowsUpToLine,
    faPause,
    faClock,
    faGaugeHigh,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '../../ui-components'

export default function Implements() {
    return (
        <>
            <Box p={4}>
                <Switch
                    id="mower_control"
                    label="Mower"
                    className="middle-input"
                    config={{ min: 0, max: 100 }}
                    pr={4}
                    options={[
                        <>
                            <FontAwesomeIcon icon={faPowerOff} size="sm" /> OFF
                        </>,
                        <>
                            <FontAwesomeIcon icon={faPlay} size="sm" /> ON
                        </>,
                    ]}
                />
                <Switch
                    id="light_control"
                    label="LIGHT"
                    className="middle-input"
                    type="percent"
                    config={{ threshold: 25 }}
                />
            </Box>
            <ImplementTabs />
        </>
    )
}
