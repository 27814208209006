import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import { OneShotButton } from '../../js'
import { useSocket } from '../../contexts/SocketContext'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

const EventButton = ({ id, value, children }) => {
    const { name: oxinId } = useOxinSelected()
    const socketClient = useSocket()
    const buttonId = `event-button-${id}`
    useEffect(() => {
        const eventButton = new OneShotButton(buttonId, { value }, socketClient, oxinId)
        return () => {
            eventButton.destroy()
        }
    }, [])

    return (
        <Button id={buttonId} variant="contained" size="large" data-action={id} data-state={value}>
            {children}
        </Button>
    )
}

export default EventButton
