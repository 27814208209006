export class Watchdog {
    watchdog
    callback
    timeout
    name
    _handlerUpdate
    reconnect: false
    key

    constructor(name, callback, timeout, update) {
        this.callback = callback
        this.timeout = timeout
        this.name = name
        this._handlerUpdate = update
        this.key = `tele_message_${name.toUpperCase()}`
        this.reload()
    }

    reload() {
        if (this.watchdog) {
            clearTimeout(this.watchdog)
        }
        this.watchdog = setTimeout(this.callback, this.timeout)
        //console.log('init watchlog', this.watchdog, this.key, this.callback, this.timeout)
    }

    clear() {
        console.warn('CLEAR WATCHDOG', this.name, this.watchdog)
        if (this.watchdog) {
            clearTimeout(this.watchdog)
            this.callback()
        }
    }
}
