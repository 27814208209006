import React, { useState } from 'react'

import {
    faLock,
    faGasPump,
    faDroplet,
    faMap,
    faScrewdriverWrench,
    faArrowsRotate,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { StatusAvt } from '../'
import { SignalOffSVG, PowerPlugOffSVG } from '../../assets/icons'
import { useBreakpoint, useSocketTelemetry } from '../../hooks'
import { ChoiceCard, CircularProgressBar } from '../../ui-components'

const COMPONENTS_SIZES = {
    circularProgressBar: {
        default: {
            size: '5rem',
            thickness: 4,
        },
        xl: {
            size: '7rem',
            thickness: 6,
        },
        lg: {
            size: '6rem',
            thickness: 5,
        },
        md: {
            size: '4.3rem',
            thickness: 5,
        },
    },
}
const getComponentSizes = (size) => {
    return {
        circularProgressBar:
            COMPONENTS_SIZES.circularProgressBar[size] ||
            COMPONENTS_SIZES.circularProgressBar.default,
    }
}
export const MainCard = ({
    id,
    disabled,
    name,
    onClick,
    data: initialTelemetryData = {},
    maintenance = null,
}) => {
    const { getBreakPointName } = useBreakpoint()
    const componentSizes = getComponentSizes(getBreakPointName())
    const { data } = useSocketTelemetry(name, initialTelemetryData)
    const missionLoaded = !!+data.missionId
    return (
        <ChoiceCard
            data-testid={`choice-card-${id}`}
            disabled={data.disconnected}
            onClick={!data.disconnected ? onClick : undefined}
            maintenance={maintenance}
        >
            <Grid sx={{ minHeight: '12rem', width: 1, mx: 0, padding: '1rem' }} container>
                <Grid container {...{ xs: 12 }}>
                    <Grid {...{ xs: 5, lineHeight: 1 }}>
                        <Typography variant="oxin-title" component="h2">
                            {name}{' '}
                            {data.locked && (
                                <FontAwesomeIcon
                                    icon={maintenance ? faScrewdriverWrench : faLock}
                                    size="xs"
                                />
                            )}
                        </Typography>
                        <Typography
                            variant="overline"
                            component="span"
                            sx={{ lineHeight: 'inherit' }}
                        >
                            {data.vemd}
                        </Typography>
                    </Grid>
                    <Grid
                        {...{ xs: 4 }}
                        sx={{
                            fontSize: 'x-small',
                            color: 'rgb(67 67 67 / 89%)',
                            paddingTop: '0.4rem',
                        }}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} size="xs" /> {data.timeText}
                    </Grid>
                    <Grid {...{ xs: 3 }} sx={{ color: 'secondary.main', textAlign: 'end' }}>
                        <StatusAvt
                            maintenance={maintenance}
                            disconnected={data.disconnected}
                            offline={data.offline}
                            showText={true}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    {...{ xs: 12 }}
                    sx={{ color: 'secondary.main', alignItems: 'center', justifyContent: 'center' }}
                >
                    <Grid {...{ xs: 4, textAlign: 'center' }}>
                        <div style={{ marginBottom: '.5rem' }}>
                            <CircularProgressBar
                                value={data.fuelLevel}
                                {...componentSizes.circularProgressBar}
                                colorv2="#EDBA37"
                                aria-label={'Percent of fuel level'}
                            />
                        </div>
                        <FontAwesomeIcon icon={faGasPump} size="lg" color="#EDBA37" />
                    </Grid>
                    <Grid {...{ xs: 4, textAlign: 'center' }}>
                        <div style={{ marginBottom: '.5rem' }}>
                            <CircularProgressBar
                                value={data.waterLevel}
                                {...componentSizes.circularProgressBar}
                                colorv2="#44BBDA"
                                aria-label={'Percent of water level'}
                            />
                        </div>

                        <FontAwesomeIcon icon={faDroplet} size="lg" color="#44BBDA" />
                    </Grid>
                    <Grid {...{ xs: 4, textAlign: 'center' }}>
                        <div style={{ marginBottom: '.5rem' }}>
                            <CircularProgressBar
                                value={missionLoaded ? data.percentMissionProgress : 0}
                                aria-label={'Mission progress'}
                                description={
                                    !missionLoaded ? (
                                        <Typography
                                            variant="overline"
                                            component="span"
                                            sx={{
                                                lineHeight: 'inherit',
                                                fontSize: 'xx-small',
                                            }}
                                        >
                                            NO MISSION
                                            <br />
                                            LOADED
                                        </Typography>
                                    ) : undefined
                                }
                                {...componentSizes.circularProgressBar}
                                colorv2="#A1C03F"
                            />
                        </div>

                        <FontAwesomeIcon icon={faMap} size="lg" color="#A1C03F" />
                    </Grid>
                </Grid>
            </Grid>
        </ChoiceCard>
    )
}
