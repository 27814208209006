import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { toast } from 'react-toastify'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { SwitchParameter, NumberParameter, ChemicalList } from './Parameters'
import { useSocket } from '../../contexts/SocketContext'

export function MissionModalParameters({
    open = false,
    onClose,
    id,
    missionToLoad = {},
    onLoadMission,
    oxinId,
}) {
    const theme = useTheme()
    const socketClient = useSocket()
    const [loadingLoadMission, setLoadingLoadMission] = React.useState(false)
    const [parametersValue, setParametersValue] = React.useState({ ...initialData })
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))
    const maxWaypoints = missionToLoad.maxWaypoints
    const isDesktop = useMediaQuery('(min-width:1200px)')

    const _handlerLoadMission = () => {
        setLoadingLoadMission(true)
        socketClient.emit('load-parameters-to-mission', { ...parametersValue, oxinId })
        setTimeout(() => {
            socketClient.emit('load-mission', { ...missionToLoad, oxinId })
            setLoadingLoadMission(false)
            toast.success(`Mission ${missionToLoad.name} - Loading...`)
            onClose()
        }, 1500)
    }

    React.useEffect(() => {
        if (!open) {
            setParametersValue({ ...initialData })
            setLoadingLoadMission(false)
        }
    }, [open])

    const checkErrors = () => {
        if (parametersValue.sprayer && parametersValue.chemical === null)
            return 'Please choose a chemical'
        if (parametersValue.waypoint > maxWaypoints)
            return `Please change the start waypoint to another less than ${maxWaypoints}`
        if (!oxinId) return `There was an error sending ${missionToLoad.name} to load`

        return null
    }

    return (
        <div>
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                fullScreen={!isDesktop ? true : false}
                open={open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ p: 3, backgroundColor: 'white' }}>
                    LOAD MISSION: {missionToLoad.name} ({missionToLoad.id})
                </DialogTitle>
                <DialogContent
                    sx={{
                        backgroundColor: 'white',
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                    dividers={true}
                >
                    <Box style={{ paddingLeft: '1.5rem', paddingTop: '0rem', color: 'gray' }}>
                        <Grid container>
                            <Grid container {...{ xs: 12, sm: 8 }}>
                                <Grid {...{ xs: 12, sm: 6 }}>
                                    <SwitchParameter
                                        value={parametersValue.mower}
                                        setValue={(value) => {
                                            setParametersValue({ ...parametersValue, mower: value })
                                        }}
                                        label={'Mower'}
                                    />
                                </Grid>
                                <Grid {...{ xs: 12, sm: 6 }}>
                                    <SwitchParameter
                                        value={parametersValue.arms}
                                        setValue={(value) => {
                                            setParametersValue({ ...parametersValue, arms: value })
                                        }}
                                        label={'Arms'}
                                    />
                                </Grid>
                                <Grid {...{ xs: 12, sm: 6 }}>
                                    <SwitchParameter
                                        value={parametersValue.sprayer}
                                        setValue={(value) => {
                                            setParametersValue({
                                                ...parametersValue,
                                                sprayer: value,
                                            })
                                        }}
                                        label={'Sprayer'}
                                    />
                                </Grid>
                                {!!parametersValue.sprayer && (
                                    <Grid {...{ xs: 12, sm: 6, pr: { sx: 0, sm: '1rem' } }}>
                                        <ChemicalList
                                            setValue={(value) => {
                                                console.debug(
                                                    'CHEMICAL CHOOSE',
                                                    value,
                                                    parametersValue
                                                )
                                                setParametersValue({
                                                    ...parametersValue,
                                                    chemical: value,
                                                    speed:
                                                        value && value.speed
                                                            ? value.speed
                                                            : parametersValue.speed,
                                                })
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                {...{
                                    xs: 12,
                                    sm: 4,
                                    py: 2,
                                    display: ' inline-flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '2rem',
                                    borderLeft: '2px solid rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <NumberParameter
                                    label={'SPEED'}
                                    value={parametersValue.speed}
                                    setValue={(value) => {
                                        setParametersValue({ ...parametersValue, speed: value })
                                    }}
                                    minValue={0.6}
                                    maxValue={1.7}
                                    step={0.1}
                                />
                                <NumberParameter
                                    label={'START IN waypoint'}
                                    value={parametersValue.waypoint}
                                    setValue={(value) => {
                                        setParametersValue({ ...parametersValue, waypoint: value })
                                    }}
                                    maxValue={maxWaypoints}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3, justifyContent: 'space-around' }}>
                    <Button
                        autoFocus
                        onClick={onClose}
                        size="large"
                        variant="contained"
                        disabled={loadingLoadMission}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            const errorMessage = checkErrors()
                            if (!errorMessage) _handlerLoadMission(parametersValue)
                            else toast.error(errorMessage)
                        }}
                        autoFocus
                        size="large"
                        variant="contained"
                        disabled={loadingLoadMission}
                        endIcon={
                            loadingLoadMission ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                            ) : undefined
                        }
                    >
                        {loadingLoadMission ? 'SEND' : 'Load Mission'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const initialData = {
    mower: 1,
    sprayer: 0,
    arms: 0,
    speed: 1,
    waypoint: 1,
    chemical: null,
}
