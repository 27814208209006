import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'

export const InternalErrorServer = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh)',
                gap: '1.5rem',
            }}
        >
            <Typography variant="h3" gutterBottom>
                Internal Server Error
            </Typography>
            <Typography variant="h5" gutterBottom>
                Server error 500. we fixing the problem. please try again at a later stage.
            </Typography>
            <Button
                variant="contained"
                size="large"
                sx={{ height: '5rem' }}
                onClick={() => location.reload()}
                endIcon={<FontAwesomeIcon icon={faRotateRight} />}
            >
                REALOAD PAGE
            </Button>
        </Box>
    )
}
