import React, { useState, useRef } from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import { MainCardSelected, MainCard } from '../MainCard'
export const ListMainCard = ({ list, selectedId, onChangeSelection }) => {
    return (
        <Grid
            container
            rowSpacing={{ xs: 4, sm: 2, md: 3, lg: 4, xl: 6 }}
            columnSpacing={{ xs: 2, sm: 4, md: 6, lg: 4, xl: 4 }}
            // TODO Remove the following rule - it's only for layout debug
            sx={{
                '--Grid-borderWidth': '1px',
                borderTop: 'var(--Grid-borderWidth) dashed',
                borderLeft: 'var(--Grid-borderWidth) dashed',
                borderColor: 'divider',
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) dashed',
                    borderBottom: 'var(--Grid-borderWidth) dashed',
                    borderColor: 'divider',
                },
            }}
        >
            {list.map(
                (
                    {
                        name,
                        data,
                        locked,
                        offline,
                        disconnected,
                        automatic,
                        notifications,
                        in_maintenance_id: maintenance,
                        oxinId = name.toUpperCase(),
                        //[name.toUpperCase()]: oxinId,
                    },
                    index
                ) => (
                    <Grid
                        key={`grid-${oxinId}`}
                        {...{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 4,
                            xl: 4,
                            px: { md: 6, sm: 4, xs: 2, lg: 4 },
                        }}
                    >
                        {selectedId !== index ? (
                            <MainCard
                                key={`card-${oxinId}`}
                                id={oxinId}
                                name={oxinId}
                                locked={locked || maintenance}
                                offline={offline}
                                disconnected={disconnected}
                                data={data}
                                automatic={automatic}
                                notifications={notifications}
                                maintenance={maintenance}
                                onClick={() => {
                                    onChangeSelection(index)
                                }}
                            />
                        ) : (
                            <MainCardSelected
                                key={`card-selected-${oxinId}`}
                                id={oxinId}
                                name={oxinId}
                                locked={locked || maintenance}
                                offline={offline}
                                automatic={automatic}
                                disconnected={disconnected}
                                notifications={notifications}
                                data={data}
                                maintenance={maintenance}
                                onClick={() => {
                                    onChangeSelection(index)
                                }}
                            />
                        )}
                    </Grid>
                )
            )}
        </Grid>
    )
}
