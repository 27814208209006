import React, { useState, useRef } from 'react'

import { faCircleDot, faBan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography'

import { SignalOffSVG, PowerPlugOffSVG } from '../../assets/icons'

const StatusDescription = ({ showText, disconnected, offline, maintenance }) => {
    return showText ? (
        <Typography
            variant="overline"
            component="span"
            sx={{
                lineHeight: 'inherit',
                fontSize: maintenance ? 'xx-small' : 'x-small',
                pr: '0.5rem',
                top: disconnected ? '-5px' : '',
                position: 'relative',
            }}
        >
            {disconnected
                ? 'Power off'
                : maintenance
                ? 'Maintenance'
                : offline
                ? 'Offline'
                : 'Online'}
        </Typography>
    ) : (
        <></>
    )
}
export const StatusAvt = ({
    disconnected,
    offline,
    maintenance = false,
    showText = false,
}: {
    maintenance?: boolean
    disconnected: boolean
    offline: boolean
    showText?: boolean
}) => {
    return (
        <>
            <StatusDescription
                maintenance={maintenance}
                disconnected={disconnected}
                offline={offline}
                showText={showText}
            />
            {disconnected ? (
                <PowerPlugOffSVG style={{ width: '1.6rem' }} />
            ) : maintenance ? (
                <FontAwesomeIcon icon={faCircleDot} size="lg" style={{ color: '#FFC107' }} />
            ) : offline ? (
                <FontAwesomeIcon icon={faCircleDot} size="lg" style={{ color: '#9E9E9E' }} />
            ) : (
                <FontAwesomeIcon
                    icon={faCircleDot}
                    beat
                    size="lg"
                    style={{ color: 'var(--green)', '--fa-animation-duration': '2s' }}
                />
            )}
        </>
    )
}
