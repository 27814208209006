import React from 'react'

export const SignalOffSVG = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x={0} y={0} viewBox="0 0 24 24" xmlSpace="preserve" {...props}>
        <path d="M18,3V16.18L21,19.18V3H18M4.28,5L3,6.27L10.73,14H8V21H11V14.27L13,16.27V21H16V19.27L19.73,23L21,21.72L4.28,5M13,9V11.18L16,14.18V9H13M3,18V21H6V18H3Z" />
    </svg>
)

export const PowerPlugOffSVG = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x={0} y={0} viewBox="0 0 24 24" xmlSpace="preserve" {...props}>
        <path d="M20.84 22.73L15.31 17.2L14.5 18V21H9.5V18L6 14.5V9C6 8.7 6.1 8.41 6.25 8.14L1.11 3L2.39 1.73L22.11 21.46L20.84 22.73M18 14.5V9C18 8 17 7 16 7V3H14V7H10.2L17.85 14.65L18 14.5M10 3H8V4.8L10 6.8V3Z" />
    </svg>
)

export const UserIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x={0} y={0} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18c-1.2 0-2.4-.3-3.5-.7.6-1.3 2-2.2 3.5-2.2s2.9.9 3.5 2.2c-1.1.4-2.3.7-3.5.7zm6.5-2.9c-.4.4-.8.8-1.3 1.1a5.989 5.989 0 00-10.6 0c-.5-.3-.9-.7-1.3-1.1L4 16.5c2.1 2.3 5 3.5 8 3.5s5.9-1.3 8-3.5l-1.5-1.4z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C9.7 4 7.8 5.9 7.8 8.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S14.3 4 12 4zm0 6.4c-1.2 0-2.2-1-2.2-2.2C9.8 7 10.8 6 12 6s2.2 1 2.2 2.2c0 1.2-1 2.2-2.2 2.2z"
        />
    </svg>
)

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4 12l3.5-3.5-1.4-1.4-3.5 3.5-3.5-3.5-1.4 1.4 3.5 3.5-3.5 3.5 1.4 1.4 3.5-3.5 3.5 3.5 1.4-1.4z" />
    </svg>
)

export const ExampleIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <circle cx="20" cy="20" r="20" fill="#EEEDF2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.111 9.11133V15.3873C30.6749 15.5069 31.7777 16.8802 31.7777 18.3789C31.7777 19.8775 30.6749 21.2508 29.111 21.3704V27.4241L27.2396 26.5988C26.2185 26.1485 24.4201 25.4584 22.4069 24.8831C21.3156 24.5712 20.1884 24.3011 19.1053 24.1181L21.1169 30.9344H17.5424L15.2929 23.7606C12.6037 23.2624 10.4443 21.0774 10.4443 18.2677C10.4443 15.0608 13.2574 12.6677 16.4606 12.6677C18.2247 12.6677 20.3777 12.2322 22.4069 11.6524C24.4201 11.0771 26.2185 10.387 27.2396 9.93666L29.111 9.11133ZM23.1396 14.2164C21.0211 14.8218 18.5905 15.3344 16.4606 15.3344C14.4911 15.3344 13.111 16.7618 13.111 18.2677C13.111 19.7737 14.4911 21.2011 16.4606 21.2011C18.5905 21.2011 21.0211 21.7137 23.1396 22.319C24.3457 22.6637 25.4811 23.0474 26.4443 23.4058V13.1297C25.4811 13.4881 24.3457 13.8718 23.1396 14.2164Z"
            fill="#3659E3"
        />
    </svg>
)

export const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.1147 8.39062L12.6667 19.7577L7.88533 15.0102L6 16.8809L12.6667 23.5017L26 10.2626L24.1147 8.39062Z"
        />
    </svg>
)

export const AlertIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3334 17.3337H14.6667V10.667H17.3334V17.3337ZM17.3334 21.3337H14.6667V18.667H17.3334V21.3337ZM16.0002 24.0007C11.5895 24.0007 8.00016 20.4113 8.00016 16.0007C8.00016 11.59 11.5895 8.00065 16.0002 8.00065C20.4108 8.00065 24.0002 11.59 24.0002 16.0007C24.0002 20.4113 20.4108 24.0007 16.0002 24.0007M16.0002 5.33398C10.1095 5.33398 5.3335 10.11 5.3335 16.0007C5.3335 21.8913 10.1095 26.6673 16.0002 26.6673C21.8908 26.6673 26.6668 21.8913 26.6668 16.0007C26.6668 10.11 21.8908 5.33398 16.0002 5.33398"
        />
    </svg>
)

export const AlertOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3334 17.3337H14.6667V10.667H17.3334V17.3337ZM17.3334 21.3337H14.6667V18.667H17.3334V21.3337ZM16.0002 24.0007C11.5895 24.0007 8.00016 20.4113 8.00016 16.0007C8.00016 11.59 11.5895 8.00065 16.0002 8.00065C20.4108 8.00065 24.0002 11.59 24.0002 16.0007C24.0002 20.4113 20.4108 24.0007 16.0002 24.0007M16.0002 5.33398C10.1095 5.33398 5.3335 10.11 5.3335 16.0007C5.3335 21.8913 10.1095 26.6673 16.0002 26.6673C21.8908 26.6673 26.6668 21.8913 26.6668 16.0007C26.6668 10.11 21.8908 5.33398 16.0002 5.33398"
        />
    </svg>
)

export const AnimatedLoadingIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7499 3.00022L21.0001 3V9.74989H14.2502L14.2499 7.50015H17.0191C15.7839 6.12099 13.993 5.25005 11.9999 5.25005C8.27847 5.25005 5.25006 8.27846 5.25006 11.9999C5.25006 15.7212 8.27847 18.7497 11.9999 18.7497C13.5174 18.7497 14.9146 18.24 16.043 17.3918L17.3941 19.1918C15.89 20.3224 14.0259 20.9996 11.9999 20.9996C7.02975 20.9996 3.00012 16.97 3.00012 11.9999C3.00012 7.02975 7.02975 3.00012 11.9999 3.00012C14.6904 3.00012 17.1023 4.18155 18.7499 6.05337V3.00022Z"
        />
    </svg>
)

export const ArrowRightChunkyIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 5.5L16 11H4v2h12l-5.5 5.5L12 20l8-8-8-8z"
        />
    </svg>
)
export const BullhornIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.0068 5.10938V11.3834C25.5711 11.5026 26.6743 12.876 26.6743 14.375C26.6743 15.874 25.5711 17.2474 24.0068 17.3666V23.4222L22.1354 22.5968C21.1143 22.1465 19.3158 21.4564 17.3026 20.8811C16.2112 20.5692 15.0839 20.2991 14.0006 20.116L16.0123 26.9328H12.4378L10.1882 19.7585C7.49922 19.2602 5.34009 17.0753 5.34009 14.2658C5.34009 11.0589 8.15319 8.66578 11.3563 8.66578C13.1205 8.66578 15.2735 8.23027 17.3026 7.65043C19.3158 7.07515 21.1143 6.38505 22.1354 5.93471L24.0068 5.10938ZM24.0076 14.375C24.0076 14.3848 24.0073 14.3945 24.0068 14.4039V14.3461C24.0073 14.3555 24.0076 14.3652 24.0076 14.375ZM18.0353 10.2145C15.9169 10.8198 13.4862 11.3324 11.3563 11.3324C9.38689 11.3324 8.00675 12.7598 8.00675 14.2658C8.00675 15.7717 9.38689 17.1991 11.3563 17.1991C13.4862 17.1991 15.9169 17.7117 18.0353 18.3171C19.2414 18.6617 20.3769 19.0454 21.3401 19.4038V9.12774C20.3769 9.48611 19.2414 9.8698 18.0353 10.2145Z"
        />
    </svg>
)

export const CheckChunkierIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.3653 4.94851C15.2028 4.78718 14.9406 4.78718 14.7781 4.94851L7.91668 11.7614L5.22192 9.08573C5.05949 8.92444 4.79736 8.9244 4.63487 9.08563L3.45653 10.2548C3.37771 10.333 3.33337 10.4394 3.33334 10.5505C3.33332 10.6615 3.37761 10.768 3.4564 10.8462L7.62307 14.9843C7.78554 15.1456 8.04777 15.1456 8.21026 14.9843L16.5436 6.70986C16.6224 6.63163 16.6667 6.52521 16.6667 6.41419C16.6667 6.30317 16.6224 6.19674 16.5436 6.11852L15.3653 4.94851Z"
        />
    </svg>
)
export const CheckChunkyIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x="0" y="0" viewBox="0 0 24 24" xmlSpace="preserve">
        <path
            d="M5.5 12.2L9.4 16l9.1-9"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
            stroke="white"
        />
    </svg>
)
export const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 10.224L12 15.1602L17 10.224L15.586 8.82682L12 12.3677L8.415 8.82682L7 10.224Z"
            fill="#4B4D63"
        />
    </svg>
)
export const EyeOnIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x="0" y="0" viewBox="0 0 24 24" xmlSpace="preserve">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 16.2c-2.3 0-4.2-1.9-4.2-4.2 0-2.3 1.9-4.2 4.2-4.2 2.3 0 4.2 1.9 4.2 4.2 0 2.3-1.9 4.2-4.2 4.2zm0-10.5c-4.2 0-7.8 2.6-9.2 6.3 1.5 3.7 5 6.3 9.2 6.3s7.8-2.6 9.2-6.3c-1.4-3.7-5-6.3-9.2-6.3z"
        />
        <circle fillRule="evenodd" clipRule="evenodd" cx="12" cy="12" r="2.5" />
    </svg>
)
export const FlagIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1111 18.4444V7.55556L25.1227 7.55556L22.2221 13L25.3332 18.4444L19.1111 18.4444ZM9.77764 16.9804H17.5555V6H8.22209V24.6667H6.66675V26H11.3334V24.6667H9.77764V16.9804Z"
        />
    </svg>
)
export const HeartIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 144 144">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.144 30C90.024 30 83.898 32.388 79.254 37.158L71.94 44.67L64.74 37.272C60.096 32.502 53.976 30.114 47.85 30.114C41.73 30.114 35.61 32.502 30.966 37.272C21.678 46.818 21.678 62.43 30.966 71.976L72 114L113.034 71.862C122.322 62.316 122.322 46.698 113.034 37.158C108.39 32.388 102.264 30 96.144 30Z"
        />
    </svg>
)
export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6666 14.6663H17.3333V21.333H14.6666L14.6666 14.6663ZM14.6666 10.6663H17.3333V13.333H14.6666V10.6663ZM15.9998 7.99902C20.4104 7.99902 23.9998 11.5884 23.9998 15.999C23.9998 20.4097 20.4104 23.999 15.9998 23.999C11.5891 23.999 7.99976 20.4097 7.99976 15.999C7.99976 11.5884 11.5891 7.99902 15.9998 7.99902M15.9998 26.666C21.8905 26.666 26.6665 21.89 26.6665 15.9993C26.6665 10.1087 21.8905 5.33268 15.9998 5.33268C10.1092 5.33268 5.33317 10.1087 5.33317 15.9993C5.33317 21.89 10.1092 26.666 15.9998 26.666"
        />
    </svg>
)

export const LoadingIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7499 3.00022L21.0001 3V9.74989H14.2502L14.2499 7.50015H17.0191C15.7839 6.12099 13.993 5.25005 11.9999 5.25005C8.27847 5.25005 5.25006 8.27846 5.25006 11.9999C5.25006 15.7212 8.27847 18.7497 11.9999 18.7497C13.5174 18.7497 14.9146 18.24 16.043 17.3918L17.3941 19.1918C15.89 20.3224 14.0259 20.9996 11.9999 20.9996C7.02975 20.9996 3.00012 16.97 3.00012 11.9999C3.00012 7.02975 7.02975 3.00012 11.9999 3.00012C14.6904 3.00012 17.1023 4.18155 18.7499 6.05337V3.00022Z"
        />
    </svg>
)

export const MyIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 10.4922C8.442 10.4922 8.8 10.8502 8.8 11.2922C8.8 11.7342 8.442 12.0922 8 12.0922C7.558 12.0922 7.2 11.7342 7.2 11.2922C7.2 10.8502 7.558 10.4922 8 10.4922V10.4922ZM12.8 12.8922C13.242 12.8922 13.6 13.2502 13.6 13.6922C13.6 14.1342 13.242 14.4922 12.8 14.4922C12.358 14.4922 12 14.1342 12 13.6922C12 13.2502 12.358 12.8922 12.8 12.8922V12.8922ZM17.6 8.89219C18.042 8.89219 18.4 9.25019 18.4 9.69219C18.4 10.1342 18.042 10.4922 17.6 10.4922C17.158 10.4922 16.8 10.1342 16.8 9.69219C16.8 9.25019 17.158 8.89219 17.6 8.89219V8.89219ZM4.8 19.2V14.48L7.0688 12.5892C7.3316 12.778 7.6516 12.8924 8 12.8924C8.4808 12.8924 8.9072 12.6756 9.2004 12.34L11.2364 13.358C11.2132 13.466 11.2 13.5776 11.2 13.6924C11.2 14.576 11.9164 15.2924 12.8 15.2924C13.6836 15.2924 14.4 14.576 14.4 13.6924C14.4 13.4476 14.3408 13.2184 14.2424 13.0112L16.6688 10.9892C16.9316 11.178 17.2516 11.2924 17.6 11.2924C18.4836 11.2924 19.2 10.576 19.2 9.6924C19.2 8.8088 18.4836 8.0924 17.6 8.0924C16.7164 8.0924 16 8.8088 16 9.6924C16 9.9372 16.0592 10.1664 16.1576 10.3736L13.7312 12.3956C13.4684 12.2068 13.1484 12.0924 12.8 12.0924C12.3192 12.0924 11.8928 12.3092 11.5996 12.6448L9.5636 11.6268C9.5868 11.5188 9.6 11.4072 9.6 11.2924C9.6 10.4088 8.8836 9.6924 8 9.6924C7.1164 9.6924 6.4 10.4088 6.4 11.2924C6.4 11.5372 6.4592 11.7664 6.5576 11.9736L4.8 13.4384V4H4V20H20V19.2H4.8Z"
        />
    </svg>
)

export const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 16.6676V20.0005H7.33287L17.1626 10.1708L13.8297 6.83789L4 16.6676ZM19.7396 7.59283C20.0862 7.24622 20.0862 6.68629 19.7396 6.33968L17.6599 4.25996C17.3133 3.91335 16.7533 3.91335 16.4067 4.25996L14.7803 5.8864L18.1131 9.21928L19.7396 7.59283ZM6 17L14 9L15 10L7 18L6 17Z"
            fill="#4B4D63"
        />
    </svg>
)

export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#4BE1A0" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0573 6.19531L8.33333 11.8789L5.94267 9.50509L5 10.4404L8.33333 13.7509L15 7.13132L14.0573 6.19531Z"
            fill="#4B4D63"
        />
    </svg>
)
export const EngineIconSVG = (props: React.SVGProps<SVGSVGElement>) => (
    <svg x={0} y={0} viewBox="0 0 24 24" xmlSpace="preserve" {...props}>
        <path d="M7,4V6H10V8H7L5,10V13H3V10H1V18H3V15H5V18H8L10,20H18V16H20V19H23V9H20V12H18V8H12V6H15V4H7Z" />
    </svg>
)

export const SpeedIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <mask id="lineMdSpeedLoop0">
            <path
                fill="none"
                stroke="#fff"
                strokeDasharray="56"
                strokeDashoffset="56"
                strokeLinecap="round"
                strokeWidth="2"
                d="M5 19V19C4.69726 19 4.41165 18.8506 4.25702 18.5904C3.45852 17.2464 3 15.6767 3 14C3 9.02944 7.02944 5 12 5C16.9706 5 21 9.02944 21 14C21 15.6767 20.5415 17.2464 19.743 18.5904C19.5884 18.8506 19.3027 19 19 19z"
            >
                <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="56;0" />
            </path>
            <g fillOpacity="0" transform="rotate(-100 12 14)">
                <path d="M12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14Z">
                    <animate
                        fill="freeze"
                        attributeName="d"
                        begin="0.4s"
                        dur="0.2s"
                        values="M12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14Z;M16 14C16 16.21 14.21 18 12 18C9.79 18 8 16.21 8 14C8 11.79 12 0 12 0C12 0 16 11.79 16 14Z"
                    />
                </path>
                <path
                    fill="#fff"
                    d="M12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14Z"
                >
                    <animate
                        fill="freeze"
                        attributeName="d"
                        begin="0.4s"
                        dur="0.2s"
                        values="M12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14C12 14 12 14 12 14Z;M14 14C14 15.1 13.1 16 12 16C10.9 16 10 15.1 10 14C10 12.9 12 4 12 4C12 4 14 12.9 14 14Z"
                    />
                </path>
                <set attributeName="fill-opacity" begin="0.4s" to="1" />
                <animateTransform
                    attributeName="transform"
                    begin="0.6s"
                    dur="6s"
                    repeatCount="indefinite"
                    type="rotate"
                    values="-100 12 14;45 12 14;45 12 14;45 12 14;20 12 14;10 12 14;0 12 14;35 12 14;45 12 14;55 12 14;50 12 14;15 12 14;-20 12 14;-100 12 14"
                />
            </g>
        </mask>
        <rect width="24" height="24" fill="currentColor" mask="url(#lineMdSpeedLoop0)" />
    </svg>
)

export const DistanceInProgressIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M6 4a2 2 0 1 0 0 4a2 2 0 0 0 0-4M2 6a4 4 0 0 1 7.874-1h8.209l.081.014a5.453 5.453 0 0 1 2.32 1.005C21.288 6.637 22 7.614 22 9s-.71 2.363-1.517 2.981a5.452 5.452 0 0 1-2.319 1.005l-.081.014H6c-.237 0-.772.148-1.25.53c-.447.358-.75.842-.75 1.47s.303 1.112.75 1.47c.478.382 1.013.53 1.25.53h11v-2.5l4.667 3.5L17 21.5V19H6c-.763 0-1.728-.352-2.5-.97C2.697 17.389 2 16.373 2 15c0-1.372.697-2.388 1.5-3.03c.772-.618 1.737-.97 2.5-.97h11.91c.374-.075.917-.27 1.357-.606c.444-.34.733-.78.733-1.394c0-.614-.29-1.053-.733-1.394A3.475 3.475 0 0 0 17.91 7H9.874A4.002 4.002 0 0 1 2 6"
        />
    </svg>
)

export const WaypointIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5 9a7 7 0 1 1 8 6.93V21a1 1 0 1 1-2 0v-5.07A7.001 7.001 0 0 1 5 9m5.94-1.06A1.5 1.5 0 0 1 12 7.5a1 1 0 1 0 0-2A3.5 3.5 0 0 0 8.5 9a1 1 0 0 0 2 0c0-.398.158-.78.44-1.06"
            clipRule="evenodd"
        />
    </svg>
)

export const DistanceIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M18 15a3 3 0 0 1 3 3a3 3 0 0 1-3 3a2.99 2.99 0 0 1-2.83-2H14v-2h1.17c.41-1.17 1.52-2 2.83-2m0 2a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m0-9a1.43 1.43 0 0 0 1.43-1.43a1.43 1.43 0 1 0-2.86 0A1.43 1.43 0 0 0 18 8m0-5.43a4 4 0 0 1 4 4C22 9.56 18 14 18 14s-4-4.44-4-7.43a4 4 0 0 1 4-4M8.83 17H10v2H8.83A2.99 2.99 0 0 1 6 21a3 3 0 0 1-3-3c0-1.31.83-2.42 2-2.83V14h2v1.17c.85.3 1.53.98 1.83 1.83M6 17a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M6 3a3 3 0 0 1 3 3c0 1.31-.83 2.42-2 2.83V10H5V8.83A2.99 2.99 0 0 1 3 6a3 3 0 0 1 3-3m0 2a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1m5 14v-2h2v2zm-4-6H5v-2h2z"
        />
    </svg>
)

export const RobotIconSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path d="M3 15a4 4 0 1 0 8 0a4 4 0 1 0-8 0m4 0v.01M17 17a2 2 0 1 0 4 0a2 2 0 1 0-4 0m-6.5 0H17" />
            <path d="M20 15.2V11a1 1 0 0 0-1-1h-6l-2-5H5v6.5" />
            <path d="M18 5h-1a1 1 0 0 0-1 1v4" />
        </g>
    </svg>
)
