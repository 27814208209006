import React, { useState, useEffect, useContext } from 'react'
import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import {
    faCircleDot,
    faCircleCheck,
    faCircleXmark,
    faCircleQuestion,
    faSpinner,
    faCircleRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabelSvgIcon, LabelFontAwesomeIcon } from '../MissionsDetails/MissionItem'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'
import { RobotIconSVG } from '../../assets/icons'
import { MissionTabContext } from '../MissionsDetails'
import { useMissions, useOxinLastReportData } from '../../hooks'
import { transformMissionData } from '../../utils'
import Chip from '@mui/material/Chip'

import {
    SpeedIconSVG,
    DistanceIconSVG,
    WaypointIconSVG,
    DistanceInProgressIconSVG,
} from '../../assets/icons'

const ChipParameter = ({ label = 'ON', type, icon = faCircleCheck }) => (
    <Chip
        variant="outlined"
        color={type}
        size="small"
        label={label.toUpperCase()}
        deleteIcon={<FontAwesomeIcon icon={icon} />}
        onDelete={() => {}}
    />
)
const ChipParameterOn = () => <ChipParameter type="success" />
const ChipParameterOff = () => <ChipParameter label="off" icon={faCircleXmark} />
const ChipParameterUNKNOWN = () => <ChipParameter label="unknown" icon={faCircleQuestion} />
const LoadingParameter = () => <FontAwesomeIcon icon={faSpinner} spin />

const mapKey = {
    missionId: ({ value }) => (value ? value : 'NONE'),
    missionName: ({ value, missionId }) =>
        !value ? missionId ? <LoadingParameter /> : 'NONE' : value,
    mower: ({ value }) => (value ? <ChipParameterOn /> : <ChipParameterOff />),
    arms: ({ value }) => <ChipParameterUNKNOWN />,
    sprayer: ({ value }) => <ChipParameterUNKNOWN />,
}
const labelKeys = {
    missionName: 'Mission Running',
    points: 'Total waypoint in mission path',
    nextPoint: 'Next Waypoint',
}

const svgIconKey = {
    vehicle: <RobotIconSVG />,
    speed: <SpeedIconSVG />,
    points: <WaypointIconSVG />,
}

const faIconKey = {
    nextPoint: faCircleRight,
}
export function MissionParametersApplied() {
    const { name: oxinId } = useOxinSelected()
    const isTabVisible = useContext(MissionTabContext)
    const [refreshLastData, setRefreshLastData] = useState(false)

    const { data, isSuccess: isLastDataSuccess } = useOxinLastReportData(oxinId, {
        enabled: refreshLastData,
        refetchInterval: 5000,
    })

    useEffect(() => {
        console.debug('MissionParametersApplied isTabVisible', isTabVisible)
        if (isTabVisible == 1) {
            setRefreshLastData(true)
        } else {
            setRefreshLastData(false)
        }
    }, [isTabVisible])

    const report = isLastDataSuccess ? transformMissionData({ telemetry: data }) : false
    const info = isLastDataSuccess ? Object.keys(report) : []
    console.log('report', report, info)

    if (!report) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: ' -webkit-fill-available',
                }}
            >
                <CircularProgress size="5rem" />
            </div>
        )
    } else {
        return (
            <Box>
                {info.map((title) => (
                    <div
                        key={`mission-applied-${title}`}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {!!svgIconKey[title] ? (
                            <LabelSvgIcon
                                label={labelKeys[title] || title}
                                sx={{ gap: '1.1rem', '> svg': { width: '24px' } }}
                            >
                                {svgIconKey[title]}
                            </LabelSvgIcon>
                        ) : (
                            <LabelFontAwesomeIcon
                                icon={faIconKey[title] ? faIconKey[title] : faCircleDot}
                                label={labelKeys[title] || title}
                                sx={{ gap: '1.5rem', '> svg': { width: '24px' } }}
                            />
                        )}

                        {mapKey[title] ? (
                            mapKey[title]({ value: report[title], ...report })
                        ) : (
                            <Typography variant="button" display="inline-block" gutterBottom>
                                {report[title]}
                            </Typography>
                        )}
                    </div>
                ))}
            </Box>
        )
    }
}
