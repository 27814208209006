import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

export default function NotFoundPage() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '90vh',
            }}
        >
            <Typography variant="h1" sx={{ color: 'var(--avt-oxin)', mb: 2 }}>
                404
            </Typography>
            <Typography variant="h6" sx={{ color: 'var(--avt-oxin)', mb: 4 }}>
                The page you’re looking for doesn’t exist.
            </Typography>
            <NavLink to={`/`} style={{ color: 'inherit' }}>
                <Button variant="contained" size="large">
                    Back Home
                </Button>
            </NavLink>
        </Box>
    )
}
