import { useQuery } from '@tanstack/react-query'
import axiosApiInstance from '../api/config/axios'

const getMissionStats = (missionId): Promise<any> => {
    return axiosApiInstance.get(`/mission/${missionId}/stats`)
}

export const useMissionStats = (missionId, options = {}) => {
    try {
        const data = useQuery({
            queryKey: [`oxin-nav-mission-stats`, missionId],
            queryFn: async () => {
                if (!missionId) return
                const mission = await getMissionStats(missionId)
                return mission?.data
            },
            staleTime: 1000 * 60 * 60,
            ...options,
        })
        return data
    } catch (error) {
        console.error('src/hooks/useMissionStats.tsx error', error)
        return {}
    }
}
