import React from 'react'
import Box from '@mui/material/Box'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

export function a11yProps(id: string, index: number) {
    return {
        id: `${id}-tab-${index}`,
        'aria-controls': `${id}-tabpanel-${index}`,
    }
}

export const TabPanel = (props: TabPanelProps) => {
    const { id, children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${id}-tabpanel-${index}`}
            aria-labelledby={`${id}-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    )
}
