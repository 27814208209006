import React, { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2'
import { useBreakpoint } from '../../hooks'

import { Map } from '../../ui-components'

import { DriveController } from './DriveController'
import { DriveTabs } from './Tabs'

export default function Drive({ avt }) {
    const [expanded, setExpanded] = useState<string | false>('panel1')

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    const { getBreakPointName } = useBreakpoint()
    const size = getBreakPointName()
    const sizeGrid = size != 'xs' ? 16 : 12

    const body =
        size == 'xs' ? (
            <ContainerComponent size={size}>
                <Grid xs={12}>
                    <DriveTabs />
                </Grid>
                <Grid xs={12}></Grid>
                <Grid xs={12} container>
                    <DriveController />
                </Grid>
            </ContainerComponent>
        ) : (
            <DriveController size={size}>
                <Grid xs={10} pl={3} pr={3} container sx={{ display: '-webkit-box' }}>
                    <DriveTabs />
                </Grid>
            </DriveController>
        )
    return (
        <>
            <Grid
                container
                rowSpacing={{ xs: 3, sm: 2, md: 3, lg: 4, xl: 6 }}
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }}
            >
                <Grid alignItems="center" {...{ xs: 12 }}>
                    <Box sx={{ flexGrow: 1 }}>{body}</Box>
                </Grid>
            </Grid>
        </>
    )
}

export const ContainerComponent = (props: Grid2Props & { children; size }) => (
    <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        spacing={2}
        columns={props.size != 'xs' ? 16 : undefined}
        sx={{ pt: 2 }}
        {...props}
    >
        {props.children}
    </Grid>
)
