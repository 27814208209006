import {
    faMapLocation,
    faVideo,
    faCircleInfo,
    faGamepad,
    faSliders,
    faBell,
} from '@fortawesome/free-solid-svg-icons'

import { PATHS } from '../constans'
const useNavButtons = () => {
    const defaultButtons = [
        {
            title: 'Missions',
            to: PATHS.MISSIONS,
            icon: faMapLocation,
            key: 'mission_page',
        },
        {
            title: 'Implements',
            to: PATHS.IMPLEMENTS,
            icon: faSliders,
            onlyToolbar: true,
            key: 'implements_page',
        },
        {
            title: 'Camera',
            to: PATHS.CAMERA,
            icon: faVideo,
            key: 'camera_page',
        },
        {
            title: 'Info',
            to: PATHS.INFO,
            icon: faCircleInfo,
            key: 'info_page',
        },
        {
            to: PATHS.NOTIFICATIONS,
            icon: faBell,
            onlyToolbar: true,
            key: 'notification_page',
        },
    ]

    const getNavButtons = ({ userIsOwner, name, offline, automatic }) => {
        const buttons = [...defaultButtons].map((v) => ({ ...v, to: `${name}/${v.to}` }))

        if (userIsOwner && !offline && !automatic)
            buttons.unshift({
                title: 'Drive',
                to: `${name}/${PATHS.DRIVE}`,
                icon: faGamepad,
                key: 'drive_page',
            })

        return buttons
    }

    return {
        getNavButtons,
    }
}

export default useNavButtons
