import React from 'react'

import { faRightFromBracket, faSquare, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { useAuth } from '../../lib/useAuth'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

import ButtonsOxinSelected from './ButtonsOxinSelected'
import selectOxinIcon from './selectOxin.png'

export const Footer = () => {
    const auth = useAuth()
    const oxin = useOxinSelected()
    console.log('OXIN-------- SELECCTION', oxin)
    return (
        <Box>
            <Paper
                //TODO CHANGE TO REM
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    minHeight: '60px',
                    //p: 2,
                    '& button': { maxHeight: '2rem', p: '1rem 2rem' },
                    '& hr': {
                        borderBottomWidth: '35px!important',
                        borderWidth: '1px',
                        bgcolor: '#F5F5F5',
                    },
                    maxHeight: '3rem',
                    padding: '0.875rem',
                }}
                elevation={3}
            >
                <Box sx={{ justifyContent: 'space-between', flexGrow: 1, display: { xs: 'flex' } }}>
                    {!!oxin ? (
                        <ButtonsOxinSelected />
                    ) : (
                        <Typography
                            variant="button"
                            display="block"
                            gutterBottom
                            sx={{ width: '100%', textAlign: 'center' }}
                        >
                            <FontAwesomeIcon
                                icon={faCircleQuestion}
                                bounce
                                style={{ color: '#176786' }}
                            />
                            {'  '}
                            Please select an OXIN touching a card above
                        </Typography>
                    )}
                    <Divider orientation="vertical" />
                    <Button
                        variant="contained"
                        onClick={auth.signOut}
                        sx={{ bgcolor: 'var(--avt-oxin-gray-dark)' }}
                    >
                        LOGOUT
                    </Button>
                </Box>
            </Paper>
        </Box>
    )
}
