export default class Publisher {
    state_attr = 'data-state'
    action_attr = 'data-action'
    #socket
    #oxinId
    constructor(socketClient, oxinId) {
        this.#socket = socketClient
        this.#oxinId = oxinId
    }
    get state() {
        return +this.$el.getAttribute(this.state_attr)
    }

    set state(value) {
        this.$el.setAttribute(this.state_attr, value)
    }

    get action() {
        return this.$el.getAttribute(this.action_attr)
    }

    publishCommand() {
        if (typeof this.state === 'number') {
            if (!this.#oxinId || !this.#socket || !this.#socket.emit) {
                console.error('Not found oxinId or socketClient', this.#oxinId, this.#socket)
                console.log(this.#socket.emit)
            } else {
                const payload = { value: this.state, oxinId: this.#oxinId }
                console.warn('publishCommand------- ' + this.action, payload)
                this.#socket.emit(this.action, payload)
            }
        }
    }
}

export class PublishMessage extends Publisher {
    constructor(containerId, socketClient, oxinId) {
        super(socketClient, oxinId)
        this.$el = document.getElementById(containerId)
    }
}
