import * as React from 'react'

import classNames from 'classnames'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import styles from './map.module.scss'
const LINZ_URL = process.env.REACT_APP_MAP_TILES_URL

export const Map = ({
    fullHeight = false,
    children,
    latlng,
    setMap,
}: {
    fullHeight?: boolean
    children?: any
    latlng: []
    setMap?: any
}) => {
    const position = latlng
    //console.log('position', position)
    //const cx = classNames(styles.container,{});
    const cx = classNames({ [styles.container]: !fullHeight, [styles.containerFull]: fullHeight })
    return (
        <div className={styles.root}>
            <MapContainer
                className={cx}
                center={position}
                zoom={16}
                maxZoom={25}
                whenReady={setMap}
                scrollWheelZoom={false}
                zoomControl={false}
            >
                <TileLayer url={LINZ_URL} maxZoom={25} />

                {children}
            </MapContainer>
        </div>
    )
}
