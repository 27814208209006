import React, { ReactNode } from 'react'

import Paper from '@mui/material/Paper'
import classNames from 'classnames'

import { OxinBase } from '../types'

import styles from './ChoiceCard.module.scss'

export interface ChoiceCardProps extends OxinBase {
    /**
     * Applies the selected style to the card
     */
    selected?: boolean
    /**
     * Applies the disabled style to the card.
     *
     * Note: This property only provides styling to indicate that the card is disabled. It does not manage the logic that disables events or interactions.
     * Use this property to visually indicate that the card is disabled.
     *
     */
    disabled?: boolean
    /**
     * Callback function invoked when the card is clicked. Also controls adding the styles related to a clickable card (e.g. hover state, pointer, focus).
     */
    onClick?: React.MouseEventHandler<HTMLElement>
    /**
     * Class names provided to the container element
     */
    className?: string
    /**
     * Sets the `data-testid` attribute for automated testing frameworks to reference.
     *
     * Note: This method is deprecated. Please use the `data-testid` attribute instead.
     *
     * @deprecated Please use `data-testid` instead.
     */
    testId?: string
    /**
     * Contents of the card
     */
    children: ReactNode
}

export const ChoiceCard: React.FC<ChoiceCardProps> = ({
    selected,
    disabled,
    maintenance,
    className,
    testId,
    children,
    onClick,
    ...oxinBase
}) => {
    const classes = classNames(
        styles.root,
        {
            [styles.disabled]: disabled,
            [styles.selected]: selected,
            [styles.clickable]: !!onClick,
            [styles.maintenance]: maintenance,
        },
        className
    )

    return (
        <Paper
            variant={'oxin-paper'}
            role="presentation"
            elevation={selected ? 4 : 3}
            className={classes}
            data-testid={testId}
            onClick={onClick}
            {...oxinBase}
        >
            {children}
        </Paper>
    )
}
