import { useState, useRef, useEffect } from 'react'
import { useSocket } from '../contexts/SocketContext'
import { Watchdog } from '../js/Watchdog'
import { timeAgo, timeOffline, sendLog } from '../utils'
import { useTelemetryData } from './'

// TODO IMPROVEMENT DUPLICATE CODE
export const useSocketTelemetry = (oxinName, initialTelemetryData, debugName) => {
    const watchDogRef = useRef(0)
    const updateData = useRef()
    const [oxinId, setOxinId] = useState(oxinName)
    const prevOxinIdRef = useRef<number>(oxinName)
    const topicOxinId = oxinId.toUpperCase()
    const socketClient = useSocket()

    const { setTelemetryData, setOffline, data, isLoading, resetData } =
        useTelemetryData(initialTelemetryData)

    const _handlerDisconnect = () => {
        setOffline(true)
    }

    useEffect(() => {
        console.log('\n\n\n\n', 'Socket connected? ', !!socketClient?.connected)
        if (!!!socketClient?.connected) {
            if (watchDogRef?.current) watchDogRef?.current?.clear()
        } else {
            watchDogRef.current = new Watchdog(topicOxinId, _handlerDisconnect, 1500, (message) => {
                sendLog(debugName, topicOxinId, message, key)
                setTelemetryData({ ...message, offline: false })
                watchDogRef.current.reload()
            })
        }
    }, [socketClient])

    useEffect(() => {
        const key = `tele_message_${oxinId}`
        if (watchDogRef?.current) watchDogRef?.current?.clear()
        watchDogRef.current = new Watchdog(topicOxinId, _handlerDisconnect, 1500, (message) => {
            sendLog(debugName, topicOxinId, message, key)
            setTelemetryData({ ...message, offline: false })
            watchDogRef.current.reload()
        })
        socketClient.on(watchDogRef.current.key, watchDogRef.current._handlerUpdate)
        return () => {
            console.warn(
                debugName,
                topicOxinId,
                '\n\n\n\n\nSocket off\n\n\n\n\n',
                watchDogRef.current.key
            )
            socketClient.off(watchDogRef.current.key, watchDogRef.current._handlerUpdate)
            if (watchDogRef?.current) watchDogRef?.current?.clear()
            sendLog(debugName, 'From unmont', watchDogRef.current.key)
        }
    }, [])

    useEffect(() => {
        const key = `tele_message_${oxinId}`
        console.log(
            'CHANGE OXIN------ TO:' + oxinId,
            'FROM ' + prevOxinIdRef.current,
            watchDogRef.current !== 0,
            prevOxinIdRef.current !== oxinId,
            watchDogRef.current !== 0 && prevOxinIdRef.current !== oxinId
        )
        if (watchDogRef.current !== 0 && prevOxinIdRef.current !== oxinId) {
            sendLog(
                debugName,
                topicOxinId,
                '\n\n\n\n\nSocket off\n\n\n\n\n',
                watchDogRef.current.key
            )
            resetData(oxinId)
            socketClient.off(watchDogRef.current.key, watchDogRef.current._handlerUpdate)
            if (watchDogRef?.current) watchDogRef?.current?.clear()
            sendLog(debugName, 'From CHANGE ------', oxinId)

            watchDogRef.current = new Watchdog(topicOxinId, _handlerDisconnect, 1500, (message) => {
                setTelemetryData({ ...message, offline: false })
                watchDogRef.current.reload()
            })
            socketClient.on(watchDogRef.current.key, watchDogRef.current._handlerUpdate)
            prevOxinIdRef.current = oxinId
        }
    }, [oxinId])

    return {
        data,
        isLoading,
        setOxinId,
    }
}
