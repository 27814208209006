import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'

import {
    faStopCircle,
    faPlay,
    faArrowsUpToLine,
    faPause,
    faClock,
    faGaugeHigh,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '../../ui-components'

const inputs = [
    {
        id: 'engine_control',
        label: 'ENGINE',
        config: { min: 0, max: 2 },
        pb: 3,
        options: [
            <>
                <FontAwesomeIcon icon={faStopCircle} size="sm" /> OFF
            </>,
            <>
                <FontAwesomeIcon icon={faPlay} size="sm" /> START
            </>,
        ],
    },
    {
        id: 'hitch_control',
        label: 'HITCH',
        pb: 3,
        type: 'hitch',
        options: [
            <>
                <FontAwesomeIcon icon={faStopCircle} size="sm" /> FLOAT
            </>,
            <>
                <FontAwesomeIcon icon={faPause} size="sm" /> HOLD
            </>,
            <>
                <FontAwesomeIcon icon={faArrowsUpToLine} size="sm" /> UP
            </>,
        ],
    },
    {
        id: 'rpm_control',
        label: 'RPM',
        className: 'middle-input',
        pb: 1,
        options: [
            <>
                <FontAwesomeIcon icon={faClock} size="sm" /> IDLE
            </>,
            <>
                <FontAwesomeIcon icon={faPlay} size="sm" /> RUN
            </>,
        ],
    },
    {
        id: 'gear_control',
        label: 'GEAR',
        className: 'middle-input',
        pl: 2,
        pb: 1,
        options: [
            <>
                <FontAwesomeIcon icon={faStopCircle} size="sm" /> LOW
            </>,
            <>
                <FontAwesomeIcon icon={faGaugeHigh} size="sm" /> HIGH
            </>,
        ],
    },
]
export const SettingParameters = () => {
    return (
        <Box px={4} pt={2} pb={0}>
            {inputs.map((input) => (
                <Switch {...input} />
            ))}
        </Box>
    )
}
