import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useParams } from 'react-router-dom'
import { useInitialData } from '../../hooks'
import { OxinSelectedProvider } from '../../contexts/OxinSelectedContext'
import { Footer, ListMainCard } from '../../components'

const Main = () => {
    const { oxin: oxins } = useInitialData()
    const [oxinIdSelected, setOxinIdSelected] = useState(undefined)

    const onChangeSelectionOxin = (id) => {
        if (oxinIdSelected === id) {
            setOxinIdSelected(undefined)
        } else {
            setOxinIdSelected(id)
        }
    }

    return (
        <OxinSelectedProvider value={oxins[oxinIdSelected]}>
            <Container
                component="main"
                sx={{
                    justifyContent: 'space-evenly',
                    pb: 10,
                }}
                maxWidth={false}
            >
                <Container sx={{ mt: 2, mb: 2 }} maxWidth={false}>
                    <ListMainCard
                        list={oxins}
                        selectedId={oxinIdSelected}
                        onChangeSelection={onChangeSelectionOxin}
                    />
                    <Footer />
                </Container>
            </Container>
        </OxinSelectedProvider>
    )
}

export default Main
