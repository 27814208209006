import * as React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { faPlus, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LabelToggleButtonGroup } from '../../ui-components'
import { useChemicalList } from '../../hooks'

const options = [
    { name: 'off', value: 0 },
    { name: 'on', value: 1 },
]
const switchStyles = {
    width: '8rem',
    height: '2rem',
    mb: 2,
    '& > button': { borderColor: '#E0E0E0!important' },
}

export const SwitchParameter = ({ label, value, setValue }) => (
    <LabelToggleButtonGroup
        label={label}
        onChange={setValue}
        options={options}
        selected={value}
        sx={{ ...switchStyles }}
    />
)

export const NumberParameter = ({ value, setValue, label, maxValue, minValue = 1, step = 1 }) => (
    <div>
        <Typography>{label}</Typography>
        <Stack direction={'row'} spacing={4} sx={{ pt: 1 }}>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                sx={{ height: '3rem' }}
                onClick={() => {
                    const newValue = parseFloat((value - step).toFixed(1))
                    if (newValue >= minValue) setValue(newValue)
                }}
            >
                <FontAwesomeIcon icon={faMinus} size="lg" />
            </Button>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>{value}</Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                sx={{ height: '3rem' }}
                onClick={() => {
                    const newValue = parseFloat((value + step).toFixed(1))
                    if (!maxValue || (maxValue && newValue <= maxValue)) setValue(newValue)
                }}
            >
                <FontAwesomeIcon icon={faPlus} size="lg" />
            </Button>
        </Stack>
    </div>
)

interface ChemicalListType {
    name: string
    speed: string
    nozzle_type: string
    mass_flow: string
    dosing_rate: string
    active: boolean
}

export const ChemicalList = ({ setValue }) => {
    const { data, isSuccess } = useChemicalList()
    return (
        <>
            {isSuccess ? (
                <Autocomplete
                    sx={{ paddingTop: '2.625rem' }}
                    options={data}
                    disableCloseOnSelect
                    getOptionLabel={(chem) =>
                        `${chem.name.toUpperCase()}: ${chem.speed} M/S, ${chem.nozzle_type}, ${
                            chem.mass_flow
                        } L/MIN, ${Math.ceil(chem.dosing_rate * 100)}% dosing`
                    }
                    onChange={(event: any, newValue: string | null) => {
                        setValue(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Choose a Chemical" required />
                    )}
                />
            ) : (
                <div style={{ paddingTop: '2rem' }}>
                    <FontAwesomeIcon icon={faSpinner} spin /> Please wait
                </div>
            )}
        </>
    )
}
