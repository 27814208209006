/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { toast } from 'react-toastify'

import { faXmark, faGears, faBars, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Badge from '@mui/material/Badge'

import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import Drawer from '@mui/material/Drawer'

import { StatusAvt } from '../'
import useNavButtons from '../../hooks/useNavButtons'
import { AlertNavButton } from '../Buttons/Buttons'

import { PATHS } from '../../constans'
import DrawerNav from './DrawerNav'
const drawerWidth = 240
import { useSocket } from '../../contexts/SocketContext'
import { useSocketTelemetry } from '../../hooks'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

//zindex
//https://mui.com/material-ui/customization/default-theme/?expand-path=$.zIndex

const ZINDEX_TOOLBAR = 1250

export const ToolbarAVT = ({
    oxinId,
    selected,
    notifications = 10,
    ...props
}: {
    oxinId: strin
    selected: string
}) => {
    const { getNavButtons } = useNavButtons()
    const { name } = useOxinSelected()
    const socketClient = useSocket()

    const [loadingDisarm, setLoadingDisarm] = React.useState(false)

    const handlerDisarm = () => {
        setLoadingDisarm(true)
        if (!!!socketClient?.connected) {
            toast.error(`Could not Disarm the Robot ${oxinId} `)
        } else {
            socketClient.emit('disarm', { oxinId: name.toLowerCase() })
        }
        setTimeout(() => {
            setLoadingDisarm(false)
        }, 1500)
    }
    const { data } = useSocketTelemetry(oxinId)
    const { locked, offline, automatic, disconnected, userIsOwner } = data
    const buttons = getNavButtons({ name, locked, offline, automatic, disconnected, userIsOwner })
    const navParams = useParams()
    const activePage = navParams['*']
    const { window } = props
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    const container = window !== undefined ? () => window().document.body : undefined
    return (
        <>
            <AppBar
                component="nav"
                position="fixed"
                sx={{
                    maxHeight: 'var(--appbar-max-size)',
                    bgcolor: 'var(--avt-oxin)',
                    zIndex: ZINDEX_TOOLBAR,
                }}
            >
                <Toolbar variant="dense">
                    <Box
                        sx={{
                            justifyContent: 'space-between',
                            flexGrow: 1,
                            display: { xs: 'flex' },
                            minHeight: 'var(--nav-toolbar-size)',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{
                                    lineHeight: 1,
                                    mr: 2,
                                    display: { sm: 'none', xs: 'inline-flex' },
                                }}
                            >
                                <FontAwesomeIcon icon={faBars} />{' '}
                            </IconButton>
                            <StatusAvt disconnected={disconnected} offline={offline} />
                            <NavLink to={`/`} style={{ lineHeight: 1 }}>
                                <Typography
                                    sx={{ ml: 2, flex: 1, color: 'white' }}
                                    variant={'oxin-title' as any}
                                >
                                    {name}
                                </Typography>
                            </NavLink>
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
                            <ToggleButtonGroup
                                variant={'oxin-button-nav' as any}
                                value={selected}
                                exclusive
                            >
                                {buttons.map(({ title, to, icon }) =>
                                    !!title ? (
                                        <ToggleButton
                                            value={title}
                                            key={title}
                                            selected={to.indexOf(activePage) != -1}
                                        >
                                            <NavLink
                                                to={`/${to}`}
                                                key={`${to}-nav-button`}
                                                style={{
                                                    color: 'inherit',
                                                    padding: '11px',
                                                    paddingTop: '15px',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                {title}
                                            </NavLink>
                                        </ToggleButton>
                                    ) : (
                                        <NavLink
                                            to={`/${to}`}
                                            style={{ color: 'inherit' }}
                                            key={`${to}-nav-button`}
                                        >
                                            <IconButton
                                                size="large"
                                                aria-label="show 17 new notifications"
                                            >
                                                <Badge badgeContent={notifications} color="error">
                                                    <FontAwesomeIcon icon={icon} size="xs" />
                                                </Badge>
                                            </IconButton>
                                        </NavLink>
                                    )
                                )}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Divider
                                orientation="vertical"
                                sx={{ mx: '1rem', display: { xs: 'none', sm: 'inherit' } }}
                            />
                            <Button
                                color="error"
                                variant="contained"
                                size="small"
                                onClick={handlerDisarm}
                                disabled={loadingDisarm}
                                endIcon={
                                    loadingDisarm ? (
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : undefined
                                }
                                sx={{ maxHeight: '45px', marginTop: '2px', ml: '1rem', mr: '2rem' }}
                            >
                                Disarm
                            </Button>
                            <Divider
                                orientation="vertical"
                                sx={{ px: '1rem', display: { xs: 'none', sm: 'inherit' } }}
                            />
                            <IconButton edge="end" aria-label="close" sx={{ pl: '1rem' }}>
                                <NavLink to={`/`} style={{ color: 'inherit' }}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </NavLink>
                            </IconButton>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {
                    <DrawerNav
                        handleDrawerToggle={handleDrawerToggle}
                        drawerWidth={drawerWidth}
                        avt={{ name, disconnected, offline, locked, automatic, notifications }}
                    />
                }
            </Drawer>
        </>
    )
}
