import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'
import { MissionsDetails, MissionMap } from '../../components'
import { LoadingCircularProgress, GenericDrawer } from '../../ui-components'
import { useOxinLastReportData } from '../../hooks'
import './styles.scss'

export default function Missions() {
    const { name: oxinId } = useOxinSelected()
    const { isLoading, data } = useOxinLastReportData(oxinId)
    const [missionIdSelected, setMissionIdSelected] = useState()
    const [missionIdInProgress, setMissionIdInProgress] = useState()

    useEffect(() => {
        if (!isLoading && data.miid && data.miid !== '') {
            setMissionIdInProgress(data.miid)
        }
    }, [isLoading, data])

    return (
        <>
            {isLoading === false ? (
                <>
                    <GenericDrawer anchor="right">
                        <MissionsDetails
                            oxinId={oxinId}
                            isLoading={isLoading}
                            missionIdInProgress={missionIdInProgress}
                            missionIdSelected={missionIdSelected}
                            setMissionIdSelected={setMissionIdSelected}
                        />
                    </GenericDrawer>
                    <Container
                        maxWidth={false}
                        sx={{ p: '0px!important', height: '-webkit-fill-available' }}
                    >
                        <MissionMap
                            oxinId={oxinId}
                            missionIdSelected={missionIdSelected || missionIdInProgress}
                        />
                    </Container>
                </>
            ) : (
                <LoadingCircularProgress />
            )}
        </>
    )
}
