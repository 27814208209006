import React, { useEffect, useState, useRef } from 'react'

export function useCountdown(seconds, options = {}) {
    const [count, setCount] = useState(seconds)
    const [cancele, setCancele] = useState(false)
    const [complete, setComplete] = useState(false)

    useEffect(() => {
        // Exit early if countdown is finished or it was stopped
        if (count <= 0 || cancele) {
            if (count <= 0) setComplete(true)
            return
        }

        // Set up the timer
        const timer = setInterval(() => {
            setCount((prevSeconds) => --prevSeconds)
        }, 1000)

        // Clean up the timer
        return () => {
            clearInterval(timer)
        }
    }, [count])

    const handlerCancel = () => {
        setCancele(true)
    }

    return { count, handlerCancel, cancele, complete }
}
