const AppConfig = {
    auth: process.env.NODE_ENV === 'production',
    apiUrl: process.env.REACT_APP_BACKEND_URL,
}
export default AppConfig

export const PATHS = {
    DRIVE: 'drive',
    MISSIONS: 'missions',
    NOTIFICATIONS: 'notifications',
    SETTINGS: 'settings',
    IMPLEMENTS: 'implements',
    INFO: 'info',
    CAMERA: 'camera',
}

export const NOTIFICATIONS_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
    CRITICAL: 'critical',
}

export const LOCALSTORAGE_USERNAME = 'username'
