import { useState, useEffect } from 'react'
import { timeAgo, timeOffline } from '../utils'

export const useTelemetryData = (
    _initialData = {
        fllv: 0,
        sptl: 0,
        locked: false,
        userIsOwner: false,
        hasAnotherOwner: false,
        offline: true,
    }
) => {
    const [isLoading, setIsLoading] = useState(true)
    const [telemetryData, setTelemetryData] = useState(_initialData)
    const [data, setData] = useState({})
    const [offline, setOffline] = useState()

    const resetData = (oxinId) => {
        setData({ ..._initialData, veid: oxinId })
    }
    useEffect(() => {
        setData(_transformData(telemetryData))
        setIsLoading(false)
    }, [])

    useEffect(() => {
        setData(_transformData(telemetryData))
    }, [telemetryData])

    useEffect(() => {
        if (offline !== undefined)
            setData({
                ...data,
                offline: offline,
                timeText: offline ? data.offlineTimeText : data.onlineTimeText,
            })
    }, [offline])

    return { setTelemetryData, setOffline, data, isLoading, resetData }
}

const _transformData = (raw) => {
    const aux = {
        name: raw.veid,
        fuelLevel: Math.ceil(raw.fllv),
        vemd: raw.vemd,
        waterLevel: Math.ceil(raw.sptl),
        disconnected: raw.disconnected,
        locked: !!raw.locked,
        waitPointCount: raw.wpco,
        waitPointNext: raw.wpcr,
        speed: raw.imsp,
        missionId: raw.miid,
        time: raw.time,
        offline: raw.offline !== false ? true : false,
        onlineTimeText: timeAgo(+raw.time),
        offlineTimeText: timeOffline(+raw.time),
        isAutomatic: raw.vemd == 'AUTO',
        isManual: raw.vemd == 'MANUAL',
        inMaintenance: raw.mant,
        userIsOwner: !!raw.userIsOwner,
        hasAnotherOwner: !!raw.hasAnotherOwner,
    }

    aux.percentMissionProgress = aux.waitPointCount
        ? Math.ceil(((aux.waitPointCount - (aux.waitPointNext - 1)) * 100) / aux.waitPointCount)
        : 0

    aux.timeText = aux.offline ? aux.offlineTimeText : aux.onlineTimeText

    return aux
}
