import { useEffect, useRef, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import Hls from 'hls.js'
import * as AWS from '@aws-sdk/client-kinesis-video'
import { KinesisVideoArchivedMedia } from '@aws-sdk/client-kinesis-video-archived-media'
import Box from '@mui/material/Box'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'
import { LoadingCircularProgress } from '../../ui-components'
import Alert from '@mui/material/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import IconButton from '@mui/material/IconButton'

const AWS_PARAMS = {
    region: 'ap-southeast-2',
}

const Camera = () => {
    const { name: oxinId } = useOxinSelected()
    const StreamName = oxinId?.toLowerCase()
    const videoRef = useRef(null)
    const player = useRef(new Hls())
    const [awsCredentials, setAwsCredentials] = useState({})
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    const handleRetry = () => {
        setLoading(true)
        setError(null)
        connectCamera()
    }

    const connectCamera = () => {
        if (!awsCredentials?.sessionToken) return

        const awsParams = {
            region: AWS_PARAMS.region,
            credentials: {
                accessKeyId: awsCredentials.accessKeyId,
                secretAccessKey: awsCredentials.secretAccessKey,
                sessionToken: awsCredentials.sessionToken,
            },
        }

        const kinesisVideo = new AWS.KinesisVideo(awsParams)
        const kinesisVideoArchivedContent = new KinesisVideoArchivedMedia(awsParams)

        kinesisVideo.getDataEndpoint(
            {
                StreamName,
                APIName: 'GET_HLS_STREAMING_SESSION_URL',
            },
            (err, response) => {
                if (err) {
                    setError({
                        severity: 'warning',
                        message:
                            'Oops! It seems there was an issue accessing the camera. Please try again later.',
                    })
                    console.error('kinesisVideo.getDataEndpoint', err)
                    return
                }

                kinesisVideoArchivedContent.config.endpoint = response.DataEndpoint

                kinesisVideoArchivedContent.getHLSStreamingSessionURL(
                    {
                        StreamName,
                        PlaybackMode: 'LIVE',
                        HLSFragmentSelector: {
                            FragmentSelectorType: 'PRODUCER_TIMESTAMP',
                        },
                        Expires: 43200, // 12 hours
                        ContainerFormat: 'FRAGMENTED_MP4',
                        DiscontinuityMode: 'ALWAYS',
                        DisplayFragmentTimestamp: 'NEVER',
                    },
                    (err, response) => {
                        if (err) {
                            console.error(
                                'kinesisVideoArchivedContent.getHLSStreamingSessionURL',
                                err
                            )
                            setError({
                                severity: 'info',
                                message: `The camera ${oxinId} is currently not sending images. Please make sure it's online.`,
                            })
                            return
                        }
                        const playerHls = player.current

                        playerHls.loadSource(response.HLSStreamingSessionURL)
                        playerHls.attachMedia(videoRef.current)

                        playerHls.on(Hls.Events.MANIFEST_PARSED, () => {
                            console.debug('Manifest parsed successfully')
                        })

                        playerHls.on(Hls.Events.ERROR, (event, data) => {
                            console.error(`Hls.js error: ${data.type} - ${data.details}`)
                        })

                        videoRef.current.play()
                        setLoading(false)
                    }
                )
            }
        )
    }

    useEffect(() => {
        Auth.currentCredentials()
            .then((awsCredentials) => {
                setAwsCredentials(awsCredentials)
            })
            .catch((error) => {
                console.error('Camera error', error)
            })

        const handleCognitoSessionRefresh = async () => {
            try {
                const session = await Auth.currentSession()
                console.log('Refreshed session:', session)
            } catch (error) {
                console.error('Error refreshing session:', error)
            }
        }

        const authListener = (data) => {
            const { payload } = data
            if (payload.event === 'cognitoSessionRefresh') {
                handleCognitoSessionRefresh()
            }
        }

        Hub.listen('auth', authListener)

        return () => {
            Hub.remove('auth')
            if (videoRef.current?.stop) videoRef.current?.stop()
            if (player.current?.destroy) player.current?.destroy()
        }
    }, [])

    useEffect(() => {
        connectCamera()
    }, [awsCredentials])

    return (
        <Box
            sx={{
                textAlign: 'center',
                fontSize: '2rem',
                position: 'relative',
                height: '-webkit-fill-available',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'nowrap',
                flexDirection: 'column',
            }}
        >
            {error ? (
                <Alert
                    sx={{ position: 'absolute', zIndex: 999 }}
                    severity={error.severity}
                    action={
                        error.severity !== 'warning' && (
                            <IconButton size="large" color="info" onClick={handleRetry}>
                                <FontAwesomeIcon icon={faRotateRight} />
                            </IconButton>
                        )
                    }
                >
                    {error.message}
                </Alert>
            ) : loading ? (
                <LoadingCircularProgress />
            ) : null}
            <video muted loop width="95%" type="video/webm" ref={videoRef} autoPlay />
        </Box>
    )
}

export default Camera
