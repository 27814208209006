import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import {
    useTelemetryData,
    useSocketTelemetry,
    useInitialData,
    useVehicleSelected,
} from '../../hooks'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

import { oxinIsAvailableToLock, sendLog } from '../../utils'
const ArmButton = () => {
    const { vehicle } = useVehicleSelected('arm')

    return (
        <Button
            onClick={vehicle?.sendArm}
            variant="contained"
            size="small"
            sx={{ bgcolor: 'var(--avt-oxin)' }}
        >
            ARM
        </Button>
    )
}

const DisarmButton = () => {
    const { vehicle } = useVehicleSelected('disarm')
    const [isLoading, setIsLoading] = useState(false)
    return (
        <Button
            onClick={vehicle?.sendDisarm}
            variant="contained"
            size="small"
            disabled={isLoading}
            sx={{ bgcolor: 'var(--avt-oxin)' }}
        >
            {isLoading ? (
                <>
                    <CircularProgress /> DISARM
                </>
            ) : (
                'DISARM'
            )}
        </Button>
    )
}

const DisarmDescriptionButton = ({ hasAnotherOwner = false }) => {
    return hasAnotherOwner ? (
        <>
            <Typography variant={'overline'} component="span">
                This robot is driven by another operator
            </Typography>
            <Divider orientation="vertical" />
            <DisarmButton />
        </>
    ) : (
        <DisarmButton />
    )
}

const UnlockButton = () => {
    const { vehicle } = useVehicleSelected('unlockButton')
    return (
        <Button
            onClick={vehicle?.sendUnlock}
            variant="contained"
            size="small"
            sx={{ bgcolor: 'var(--avt-oxin)' }}
        >
            UNLOCK
        </Button>
    )
}
const LockButton = ({}) => {
    const { vehicle } = useVehicleSelected('lockButton')
    const [isLoading, setIsLoading] = useState(false)
    const text = 'Lock & Take ownership vehicle'
    return (
        <>
            <Button
                onClick={() => {
                    vehicle?.sendLock()
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 3000)
                    setIsLoading(true)
                }}
                variant="contained"
                size="small"
                sx={{ bgcolor: 'var(--avt-oxin)' }}
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <CircularProgress size={24} pr={4} /> {text}
                    </>
                ) : (
                    text
                )}
            </Button>
            <Divider orientation="vertical" />
            <DisarmButton />
        </>
    )
}

const Buttons = ({}) => {
    return (
        <>
            <UnlockButton />
            <Divider orientation="vertical" />
            <ArmButton />
            <Divider orientation="vertical" />
        </>
    )
}

const FooterButtons = ({ userIsOwner, canLock, name, hasAnotherOwner }) => {
    return (
        <>
            {userIsOwner && <Buttons key={`footer-buttons-${name}`} />}

            {!userIsOwner && canLock ? (
                <LockButton key={`footer-button-lock-${name}`} />
            ) : (
                <DisarmDescriptionButton
                    key={`footer-button-disarm-${name}`}
                    hasAnotherOwner={hasAnotherOwner}
                />
            )}
        </>
    )
}

const ButtonsOxinSelected = () => {
    const { name, data: initialTelemetryData } = useOxinSelected()
    const { data, isLoading, setOxinId } = useSocketTelemetry(
        name,
        initialTelemetryData,
        'ButtonsOxinSelected_' + name
    )
    useEffect(() => {
        setOxinId(name)
    }, [name])
    const { locked, userIsOwner, hasAnotherOwner } = data

    const canLock = isLoading ? false : oxinIsAvailableToLock(data)

    sendLog(
        'footer_buttons',
        `locked: ${locked}`,
        `userIsOwner: ${userIsOwner}`,
        `canLock: ${canLock}`,
        `OXINS: ${data.name} - ${name}`,
        data
    )
    return (
        <>
            <Box key={`footer-info-${name}`}>
                <Typography
                    variant={'oxin-title' as any}
                    component="h3"
                    sx={{
                        animation: 'slide-in-left 1s ease-in-out both',
                        color: 'var(--avt-oxin)',
                    }}
                >
                    {name}
                </Typography>
            </Box>
            {!isLoading && data.name === name ? (
                <FooterButtons
                    userIsOwner={userIsOwner}
                    canLock={canLock}
                    name={name}
                    hasAnotherOwner={hasAnotherOwner}
                />
            ) : (
                <DisarmButton />
            )}
        </>
    )
}

export default ButtonsOxinSelected
