import React, { useState, useEffect, useRef } from 'react'
import Typography from '@mui/material/Typography'

import { useCountdown } from '../../hooks'
import { PublishMessage } from '../../js'
import { useSocket } from '../../contexts/SocketContext'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

const ContentCountdown = ({ seconds, action, initialState, finishAction }) => {
    const [stop, setStop] = useState(false)
    const { name: oxinId } = useOxinSelected()
    const socketClient = useSocket()
    const { count, complete, cancele, handlerCancel } = useCountdown(seconds)
    const idComponent = `description-countdown-${action}`
    const publishRef = useRef()

    const handlerStop = () => {
        setStop(true)
        const elem = publishRef.current
        elem.$el.setAttribute(elem.action_attr, finishAction || action)
        elem.state = 0
        elem.stop = true
        elem.publishCommand()
    }
    useEffect(() => {
        publishRef.current = new PublishMessage(idComponent, socketClient, oxinId)
        publishRef.current.publishCommand()
        return () => {
            if (!publishRef.current.stop) handlerStop()
        }
    }, [])

    useEffect(() => {
        if ((cancele || complete) && !stop) handlerStop()
    }, [cancele, complete, stop])

    return (
        <Typography
            id={`${idComponent}`}
            data-action={action}
            data-state={initialState}
            textAlign="center"
            component="h3"
            variant="h3"
            pt={{ sm: 2 }}
        >
            {count}
        </Typography>
    )
}

export default ContentCountdown
