/* eslint-disable react/prop-types */
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import { NavLink } from 'react-router-dom'

import { OxinBase } from '../types'

export interface MainButtonCardProps extends OxinBase {
    /**
     * Applies the disabled style to the card.
     *
     * Note: This property only provides styling to indicate that the card is disabled. It does not manage the logic that disables events or interactions.
     * Use this property to visually indicate that the card is disabled.
     *
     */
    disabled?: boolean
    /**
     * Callback function invoked when the card is clicked. Also controls adding the styles related to a clickable card (e.g. hover state, pointer, focus).
     */
    to: string
    /**
     * Sets the `data-testid` attribute for automated testing frameworks to reference.
     *
     * Note: This method is deprecated. Please use the `data-testid` attribute instead.
     *
     * @deprecated Please use `data-testid` instead.
     */
    testId?: string

    icon?: any
}

export const MainButtonAction: React.FC<MainButtonCardProps> = ({ icon, disabled }) => {
    return (
        <Button disabled={disabled} variant={'oxin-lg' as any} size="large">
            <FontAwesomeIcon icon={icon} size="2xl" />
        </Button>
    )
}

export const MainButtonActionV2: React.FC<MainButtonCardProps> = ({
    icon,
    title,
    disabled,
    to,
}) => {
    return (
        <Button
            disabled={disabled}
            variant={'oxin-lg' as any}
            size="large"
            sx={{ display: 'table-column', padding: '.6rem' }}
        >
            <NavLink to={`/${to}`} style={{ color: 'inherit' }}>
                <div>
                    <div>
                        <FontAwesomeIcon icon={icon} size="xl" />
                        <span style={{ fontSize: 'xx-small', display: 'block' }}>{title}</span>
                    </div>
                </div>
            </NavLink>
        </Button>
    )
}
//#E0E0E0
