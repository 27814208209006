import { useOxinSelected } from '../contexts/OxinSelectedContext'
import { useVehicles } from '../contexts/VehiclesContext'
import { sendLog } from '../utils'

export const useVehicleSelected = (debugName) => {
    const { name } = useOxinSelected()
    const { vehicles } = useVehicles()
    const vehicle = vehicles[name]
    sendLog(debugName, 'useVehicleSelected', vehicle, name)
    return { vehicle }
}
