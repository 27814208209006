import { useQuery } from '@tanstack/react-query'
import axiosApiInstance from '../api/config/axios'

const getMission = (missionId): Promise<any> => {
    return axiosApiInstance.get(`/mission/${missionId}`)
}

export const useMission = (missionId, options = {}) => {
    try {
        const data = useQuery({
            queryKey: [`oxin-nav-mission`, missionId],
            queryFn: async () => {
                if (!missionId || !(Number(missionId) > 0)) return {}
                const mission = await getMission(missionId)
                return mission?.data
            },
            staleTime: 1000 * 60 * 60,
            ...options,
        })
        return data
    } catch (error) {
        console.error('src/hooks/useMission.tsx error', error)
        return {}
    }
}
