import * as React from 'react'
import { Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { grey } from '@mui/material/colors'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import Drawer from '@mui/material/Drawer'
import styles from './GenericDrawer.module.scss'

const drawerBleeding = 50
const drawerWidth = '25rem'

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    children?: () => JSX.Element
}
export const DrawerContext = React.createContext({
    open: false,
    setOpen: () => {},
})

export const GenericDrawer = (props: Props) => {
    console.debug('styles', styles)
    const { children, anchor = 'left' } = props
    const [open, setOpen] = React.useState(false)
    //const puller = useRef();
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen)
    }

    // This is used only for the example
    const container = document.getElementById('mission_page')

    return (
        <>
            <Drawer
                className={styles.drawer}
                variant="persistent"
                anchor={anchor}
                classes={{
                    paper: styles.paper,
                }}
                open={open}
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <DrawerContext.Provider value={{ open, setOpen }}>
                    {children}
                </DrawerContext.Provider>
            </Drawer>
            <Box
                className={[styles.puller, styles[`puller-${anchor}`]]}
                sx={{ [anchor]: open ? `${drawerWidth}` : 0 }}
            >
                <Button onClick={toggleDrawer(!open)} size="large">
                    Missions
                </Button>
            </Box>
        </>
    )
}
