import React from 'react'
import { faXmark, faBell, faGears, faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'

import { NavLink } from 'react-router-dom'
import { PATHS } from '../../constans'
export const AlertNavButton = (props: IconButtonProps & { notifications?: number }) => {
    return (
        <NavLink to={`/${PATHS.NOTIFICATIONS}`} style={{ color: 'inherit' }}>
            <IconButton size="large" aria-label="show 17 new notifications" {...props}>
                <Badge badgeContent={props.notifications} color="error">
                    <FontAwesomeIcon icon={faBell} size="xs" />
                </Badge>
            </IconButton>
        </NavLink>
    )
}
