import { useQuery } from '@tanstack/react-query'
import axiosApiInstance from '../api/config/axios'

const getChemicalList = (): Promise<any> => {
    return axiosApiInstance.get(`/mission/chemical-list`)
}

export const useChemicalList = () => {
    try {
        const data = useQuery({
            queryKey: [`oxin-nav-chemical-list`],
            queryFn: async () => {
                const data = await getChemicalList()
                return data?.data
            },
            staleTime: 1000 * 60 * 60,
        })
        return data
    } catch (error) {
        console.error('src/hooks/useChemicalList.tsx error', error)
        return {}
    }
}
