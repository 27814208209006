// @ts-ignore
import { ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'
const typographyOptions: {} = {
    fontSize: '1.4rem',
    letterSpacing: '.1rem',
    fontFamily: 'SairaStencilOne, roboto',
}
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#176786',
            title: '#424242',
        },
        secondary: {
            main: 'rgb(67, 67, 67)',
        },
        background: {
            default: '#e0e0e0',
            paper: '#eeeeee',
        },
        donut: {
            default: '#9E9E9E',
        },
        critical: {
            main: '#d32f2f',
        },
        danger: {
            main: '#f44336',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: '0px',
                },
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'oxin-paper' },
                    style: ({ theme }) => {
                        return {
                            backgroundColor: '#F5F5F5',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            boxShadow:
                                '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                        }
                    },
                },
            ],
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'oxin-title' },
                    style: ({ theme, containedStyles }) => {
                        return {
                            ...containedStyles,
                            ...typographyOptions,
                            color: theme.palette.primary.title,
                            fontSize: '1.5rem',
                            textTransform: 'uppercase',
                        }
                    },
                },
                {
                    props: { variant: 'oxin-subtitle' },
                    style: ({ theme, containedStyles }) => {
                        return {
                            ...containedStyles,
                            ...typographyOptions,
                            color: theme.palette.primary.light,
                        }
                    },
                },
                {
                    props: { variant: 'oxin-subtitle-online' },
                    style: ({ theme, containedStyles }) => {
                        return {
                            ...containedStyles,
                            ...typographyOptions,
                            color: theme.palette.success.light,
                        }
                    },
                },
                {
                    props: { variant: 'oxin-subtitle-warning' },
                    style: ({ theme, containedStyles }) => {
                        return {
                            ...containedStyles,
                            ...typographyOptions,
                            color: theme.palette.warning.light,
                        }
                    },
                },
            ],
        },
        MuiDivider: {
            styleOverrides: {
                // Name of the slot
                vertical: {
                    margin: '0',
                    flexShrink: '0',
                    borderWidth: '0px thin 0px 0px',
                    borderStyle: 'solid',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                    height: 'auto',
                    alignSelf: 'stretch',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'oxin-lg' },
                    inherit: 'contained',
                    style: ({ theme, containedStyles }) => {
                        return {
                            ...containedStyles,
                            [theme.breakpoints.down('sm')]: {
                                margin: '1rem',
                            },
                            maxWidth: '5.5rem',
                            width: '100%',
                            height: '100%',
                            maxHeight: '3.5rem',
                            padding: '0!important',
                            background: 'var(--gray-light)',
                            color: 'var(--avt-oxin)',
                            boxShadow:
                                '0px 2px 2px rgba(10, 16, 20, 0.24), 0px 0px 2px rgba(10, 16, 20, 0.12)',
                            '&:hover': {
                                background: 'background.paper',
                            },
                            '&:active': {
                                background: 'background.paper',
                            },
                            '> a': {
                                width: '100%',
                                height: '100%',
                                display: 'block',
                            },
                            '> a >div': {
                                display: 'inline-grid',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                textAlign: 'center',
                            },
                        }
                    },
                },
            ],
        },
        MuiToggleButtonGroup: {
            variants: [
                {
                    props: { variant: 'oxin-button-nav' },
                    style: ({ theme, containedStyles }) => {
                        return {
                            '& > button': {
                                border: 'none',
                                color: 'var(--avt-oxin-gray-light)',
                                padding: 0,
                            },
                            '& > button.Mui-selected': {
                                color: 'white',
                                fontWeight: 'bold',
                                WebkitBoxShadow: 'inset 0px 0px 5px 10px rgba(14,79,102,0.57)',
                                MozBoxShadow: 'inset 0px 0px 5px 10px rgba(14, 79, 102, 0.57)',
                                boxShadow: 'inset 0px 0px 5px 10px rgba(14,79,102,0.57)',
                                background: 'rgb(14 79 102 / 29%)',
                                height: '100%',
                                display: 'inline',
                            },
                            ...containedStyles,
                            alignItems: 'center',
                            height: '100%',
                            padding: 0,
                        }
                    },
                },
            ],
        },
        MuiAlert: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
    },
}

declare module '@mui/material/styles' {
    interface Palette {
        critical: Palette['primary']
        danger: Palette['primary']
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        critical?: PaletteOptions['primary']
        danger?: PaletteOptions['primary']
    }
}

// @babel-ignore-comment-in-output Update the Button's color prop options
declare module '@mui/material/Switch' {
    interface SwitchPropsColorOverrides {
        critical: true
        danger: true
    }
}

export const theme = createTheme(themeOptions)
