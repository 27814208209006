import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ContentCountdown from './ContentCountdown'

const ModalCountdown = ({ id, label, action, modal = {} }) => {
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    let childrenModal
    switch (modal.type) {
        case 'countdown':
            childrenModal = (
                <ContentCountdown
                    seconds={modal.seconds}
                    action={action || id}
                    finishAction={modal.finishAction}
                    initialState={100}
                />
            )
            break
        default:
            childrenModal = false
            break
    }
    return (
        <>
            <Button
                id={`implement-button-sprayer-${id}`}
                variant="contained"
                size="medium"
                data-action={action || id}
                data-state="0"
                onClick={handleClickOpen}
            >
                {label.toUpperCase()}
            </Button>
            {!!childrenModal && (
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={open}
                    aria-labelledby={`alert-dialog-title-${id}`}
                    aria-describedby={`alert-dialog-description-${id}`}
                >
                    <DialogTitle id={`alert-dialog-title-${id}`}>
                        <Typography variant="h6" textAlign="center" component="h2">
                            {modal?.title?.toUpperCase()}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        {modal?.subtitle ? modal?.subtitle : <></>}
                        <DialogContentText id={`alert-dialog-description-${id}`}>
                            {childrenModal}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} size="large" variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

export default ModalCountdown
