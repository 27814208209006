import React, { useState, useEffect, useContext, createContext } from 'react'

import { faGears, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import BorderLinearProgress from './BorderLinearProgress'
import { MissionModalParameters, MissionParametersApplied } from '../MissionParameters'
import { MissionItem } from './'
import { Grid } from '@mui/material'
import { TabPanel, a11yProps, DrawerContext } from '../../ui-components'
import { getMissionActive, transformMissionData } from '../../utils'
//import { useSocket } from '../contexts/SocketContext'
import { useMissions, useOxinLastReportData } from '../../hooks'

export const MissionTabContext = createContext(0)

export const MissionsDetails = ({
    oxinId,
    missionIdSelected,
    setMissionIdSelected,
    missionIdInProgress,
}) => {
    const [tabActive, setTabActive] = React.useState(0)
    const [openParameters, setOpenParameters] = React.useState(false)
    const [missionToLoad, setMissionToLoad] = React.useState({})
    const { open: isComponentVisible, setOpen: setOpenDrawer } = useContext(DrawerContext)
    const isDesktop = useMediaQuery('(min-width:1200px)')

    const _handlerOpenModal = ({ id, name, maxWaypoints }) => {
        console.debug('_handlerOpenModal id, name ', id, name, maxWaypoints)
        setMissionToLoad({ id, name, maxWaypoints })
        setOpenParameters(true)
    }

    const _handlerSelectCard = (missionId) => {
        setMissionIdSelected(missionId)
        console.log('missionId', missionId, missionIdSelected)
        if (!isDesktop && missionId != missionIdSelected) {
            setOpenDrawer(false)
        }
    }

    const _handlerClose = () => {
        setOpenParameters(false)
    }

    const _handlerChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabActive(newValue)
    }

    const _handlerLoadMission = (parameters) => {
        handleClose()
    }
    console.debug('isComponentVisible', isComponentVisible)

    return isComponentVisible ? (
        <>
            <MissionModalParameters
                open={openParameters}
                missionToLoad={missionToLoad}
                onClose={_handlerClose}
                onLoadMission={_handlerLoadMission}
                oxinId={oxinId}
            />
            <Stack direction="row" sx={{ minWidth: 0, display: 'block' }}>
                <Tabs
                    value={tabActive}
                    onChange={_handlerChangeTab}
                    sx={{
                        '.Mui-selected': {
                            backgroundColor: '#FAFAFA',
                            height: '100%',
                            minHeight: '4.5rem',
                        },
                    }}
                    size="lg"
                >
                    <Tab label="Missions Available" {...a11yProps(0)} />
                    <Tab label="Current Parameters" {...a11yProps(1)} />
                </Tabs>
                <Box
                    sx={{
                        bgcolor: '#fafafa',
                        pt: { sm: '0.5rem', md: 1, lg: 1 },
                        px: { sm: '1.76rem', md: 2, lg: 2 },
                        height: '100%',
                    }}
                >
                    <MissionTabContext.Provider value={tabActive}>
                        <TabPanel value={tabActive} index={0} id={'missions-list'}>
                            <MissionList
                                oxinId={oxinId}
                                _handlerOpenModal={_handlerOpenModal}
                                _handlerSelectCard={_handlerSelectCard}
                                missionIdSelected={missionIdSelected}
                                missionIdInProgress={missionIdInProgress}
                            />
                        </TabPanel>
                        <TabPanel value={tabActive} index={1} id={'missions-parameters'}>
                            <MissionParametersApplied />
                        </TabPanel>
                    </MissionTabContext.Provider>
                </Box>
            </Stack>
        </>
    ) : (
        <></>
    )
}

const MissionList = ({
    oxinId,
    _handlerOpenModal,
    _handlerSelectCard,
    missionIdSelected,
    missionIdInProgress,
}) => {
    const isTabVisible = useContext(MissionTabContext)
    const [refreshLastData, setRefreshLastData] = useState(false)
    const [missionIdInRunning, setMissionIdInRunning] = useState(
        Number(missionIdInProgress) || undefined
    )
    const { isSuccess: isMissionsSuccess, data: rawMissions } = useMissions(oxinId)
    const { data, isSuccess: isLastDataSuccess } = useOxinLastReportData(oxinId, {
        enabled: refreshLastData,
        refetchInterval: 2000,
    })

    const missionRunning = isMissionsSuccess
        ? rawMissions.find((mission) => mission.id === missionIdInRunning)
        : false

    const missions = isMissionsSuccess
        ? missionRunning
            ? [missionRunning].concat(
                  rawMissions.filter((mission) => mission.id != missionIdInRunning)
              )
            : rawMissions
        : []

    useEffect(() => {
        const delayGetReportData = setTimeout(() => {
            setRefreshLastData(true)
        }, 5000)
        return () => {
            clearTimeout(delayGetReportData)
        }
    }, [])

    useEffect(() => {
        console.debug(
            'MissionList change data.miid? ',
            data?.miid != missionIdInProgress,
            ` data?.miid= ${data?.miid} `,
            `missionIdInProgress=${missionIdInProgress}`
        )
        if (data && data?.miid != missionIdInProgress) {
            if (Number(data?.miid) == 0) {
                setMissionIdInRunning(undefined)
            } else {
                setMissionIdInRunning(Number(data?.miid))
            }
        }
    }, [data])

    return (
        <Container
            disableGutters={true}
            sx={{
                height: 'calc(100vh - 10rem)',
                overflowY: 'auto',
                pb: 1,
                pt: 1,
            }}
        >
            {isMissionsSuccess && missions?.length ? (
                <Stack direction="column" sx={{ minWidth: 0, display: 'block' }} spacing={1}>
                    {missions.map(({ id, name }) => (
                        <MissionItem
                            key={id}
                            id={id}
                            name={name.toUpperCase()}
                            inprogress={id === missionIdInRunning}
                            selected={
                                missionIdSelected
                                    ? id === missionIdSelected
                                    : id === missionIdInRunning
                            }
                            missionRunning={{ ...(missionRunning || {}), ...data }}
                            onClick={_handlerSelectCard}
                            _handlerLoadMission={_handlerOpenModal}
                            enabledToLoad={!!!missionIdInRunning}
                        />
                    ))}
                </Stack>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: ' -webkit-fill-available',
                    }}
                >
                    <CircularProgress size="5rem" />
                </div>
            )}
        </Container>
    )
}
