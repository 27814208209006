const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate()
    const month = MONTH_NAMES[date.getMonth()]
    const year = date.getFullYear()
    const hours = date.getHours()
    let minutes = date.getMinutes()

    if (minutes < 10) {
        // Adding leading zero to minutes
        minutes = `0${minutes}`
    }

    if (prefomattedDate) {
        // Today at 10:20
        // Yesterday at 10:20
        return `${prefomattedDate} at ${hours}:${minutes}`
    }

    if (hideYear) {
        // 10. January at 10:20
        return `${day}. ${month} at ${hours}:${minutes}`
    }

    // 10. January 2017. at 10:20
    return `${day}. ${month} ${year}. at ${hours}:${minutes}`
}
const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000

// --- Main function
export const timeAgo = (dateParam) => {
    if (!dateParam) {
        return null
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam * 1000)
    const today = new Date()
    const yesterday = new Date(today - DAY_IN_MS)
    const seconds = Math.round((today - date) / 1000)
    const minutes = Math.round(seconds / 60)
    const isToday = today.toDateString() === date.toDateString()
    const isYesterday = yesterday.toDateString() === date.toDateString()
    const isThisYear = today.getFullYear() === date.getFullYear()

    if (seconds < 5) {
        return 'now'
    } else if (seconds < 60) {
        return `${seconds} seconds ago`
    } else if (seconds < 90) {
        return 'about a minute ago'
    } else if (minutes < 60) {
        return `${minutes} minutes ago`
    } else if (isToday) {
        return getFormattedDate(date, 'Today') // Today at 10:20
    } else if (isYesterday) {
        return getFormattedDate(date, 'Yesterday') // Yesterday at 10:20
    } else if (isThisYear) {
        return getFormattedDate(date, false, true) // 10. January at 10:20
    }

    return getFormattedDate(date) // 10. January 2017. at 10:20
}

export const timeOffline = (dateParam) => {
    if (!dateParam) {
        return null
    }
    let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }

    const today = new Date()
    const yesterday = new Date(today - DAY_IN_MS)
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
    const isToday = today.toDateString() === date.toDateString()
    const isYesterday = yesterday.toDateString() === date.toDateString()
    const isThisYear = today.getFullYear() === date.getFullYear()

    if (isToday) {
        return getFormattedDate(date, 'Today') // Today at 10:20
    } else if (isYesterday) {
        return getFormattedDate(date, 'Yesterday') // Yesterday at 10:20
    } else if (isThisYear) {
        return getFormattedDate(date, false, true) // 10. January at 10:20
    }

    return getFormattedDate(date)
}

export const getMissionActive = (telemetryData, missions) => {
    const missionActive = +telemetryData?.miid
    if (missionActive) {
        const missionId = missions?.find((element) => element.id === missionActive)
        return missionId
    } else {
        return undefined
    }
}

export const oxinIsAvailableToLock = (oxin) => {
    if (!oxin.disconnected && !oxin.offline && !oxin.inMaintenance) {
        // I need to add if the user is admin
        if (!oxin.isAutomatic && !oxin.locked) {
            //console.warn(`oxin ${oxin.name} is in Manual or in HOLD &  locked false`)
            return true
        } else {
            //console.warn(`oxin ${oxin.name} is in automatic`)
            return false
        }
    } else {
        //console.warn(`oxin ${oxin.name} is disconnect or offline or inMaintance`)
        return false
    }
}

export const sendLog = (logKey, ...arg) => {
    if (window[logKey]) {
        console.log(`sendLog->${logKey}`, ...arg)
    }
}

//TODO move this logic to
export const transformMissionData = ({ telemetry = {}, mission = {} }) => {
    const parameters = {
        vehicle: telemetry.veid,
        missionId: telemetry.miid,
        missionName: mission.name,
        points: telemetry.wpco,
        nextPoint: telemetry.wpcr,
        ETA: telemetry.meta,
        speed: `${Number((Number((telemetry.imsp / 100).toFixed(1)) * 3.6).toFixed(1))} km/h`,
        mower: telemetry.mimi,
        // TODO https://oxin.atlassian.net/browse/OXOS-1114
        sprayer: 'unknown',
        arms: 'unknown',
    }
    return parameters
}
export const transformParametersInfo = ({ telemetry = {} }) => {
    console.log('telemetry.olp1', telemetry.olp1)
    const parameters = {
        engineTemp: telemetry.enct ? `${telemetry.enct}º` : null,
        hydrOilTemp: telemetry.hdrt ? `${Math.ceil(telemetry.hdrt)}º` : null,
        hydrOilLevel: telemetry.hdvl ? Math.ceil(telemetry.hdvl) : null,
        engineLoad: telemetry.enpl,
        engineRpm: telemetry.erpm,
        battery: telemetry.slmv ? Number(telemetry.slmv / 1000).toFixed(1) + ' V' : null, // GOOD OR BAD
        mowerHydrPressure: `${telemetry.olp1 ? Number(telemetry.olp1).toFixed(0) + ' bar' : ''} ${
            telemetry.olp2 ? Number(telemetry.olp2).toFixed(0) + ' bar' : ''
        }`,
        blowerHydrPressure: telemetry.blpr
            ? Number(telemetry.blpr).toFixed(1) + ' bar'
            : telemetry.blpr,
        blowerRpm: telemetry.blrp ? Number(telemetry.blrp).toFixed(0) : telemetry.blrp,
        mode: telemetry.vemd,
        groundSpeed: telemetry.ngsp,
        gps: `${telemetry.gps1 || ''} ${telemetry.gps2 || ''}`,
        user: telemetry.user || 'No user',
        chemUsed: `${telemetry.sprl ? Number(telemetry.sprl / 1000).toFixed(3) : ''} - ${
            telemetry.sprl ? Number(telemetry.sprr / 1000).toFixed(3) : ''
        }`,
        waterTankLevel: telemetry.sptl,
        nozzleRates: `${telemetry.spnl || ''} ${telemetry.spnr || ''}`,
        osVersion: telemetry.osvr,
    }
    return parameters
}

/**
 *
 * src/utils.tsx
#param_gps1[data-status='4']::after, #param_gps2[data-status='4']::after { background-color: orange; }
#param_gps1[data-status='5']::after, #param_gps2[data-status='5']::after { background-color: blue; }
#param_gps1[data-status='6']::after, #param_gps2[data-status='6']::after { background-color: var(--oxin-green); }
 */
