import Publisher from './Publisher'

class SwitchBase extends Publisher {
    constructor(containerId, socketClient, oxinId) {
        super(socketClient, oxinId)
        this.$el = document.getElementById(`switch_inner_${containerId}`)
        this.$el.classList.add('draggable')

        this.$box = document.getElementById(this.$el.parentElement.id)
        const boxId = this.$el.parentElement.id
        this.draggie = new Draggabilly(this.$el, {
            containment: `#${boxId}`,
            axis: 'x',
        })
    }

    get state() {
        return +this.$el.getAttribute(this.state_attr)
    }

    set state(value) {
        this.draggie.element.setAttribute(this.state_attr, value)
    }

    get action() {
        return this.$el.getAttribute(this.action_attr)
    }

    get endPosition() {
        return this.$box.offsetWidth - this.$el.offsetWidth
    }

    get position() {
        // TODO TEST this math round to up
        const pos = Math.ceil((this.draggie.position.x / this.endPosition) * 100)
        return pos
    }

    setPosition(value) {
        if (!this.editable) {
            return
        }

        if (value) {
            this.percentage = value
        }
        this.state = this.percentage
        // TODO TEST this math round to up
        const position = Math.ceil(this.endPosition * this.percentage)
        this.draggie.setPosition(position / 100, 0)
        this.setValueToHTMLElement()
    }

    dragEnd() {
        this.editable = true
        this.setPosition()
        this.publishCommand()
    }

    dragMove() {
        this.editable = false
        this.percentage = this.position
        this.setValueToHTMLElement()
    }

    destroy() {
        this.draggie.destroy()
    }

    reset() {}
}

export class PercentageSwitch extends SwitchBase {
    constructor(containerId, { threshold = 1 }, socketClient, oxinId) {
        super(containerId, socketClient, oxinId)
        this.$value_box = document.querySelector(`#switch_label_${containerId}_value`)
        this._threshold = threshold
        this._percentage = 0
        this.max = 100
        this.editable = true

        this.draggie.on('dragMove', this.dragMove.bind(this))
        this.draggie.on('dragEnd', this.dragEnd.bind(this))
        this.setValueToHTMLElement(this._percentage)
    }

    set percentage(value) {
        const v = Math.round(value - (value % this._threshold))
        this._percentage = v > this.max ? this.max : v
    }

    get percentage() {
        return this._percentage
    }

    setValueToHTMLElement() {
        this.$value_box.innerText = `${this.percentage}%`
    }
}

export class TwoPositionalSwitch extends SwitchBase {
    constructor(containerId, options = {}, socketClient, oxinId) {
        super(containerId, socketClient, oxinId)
        this.min = options.min || 0
        this.max = options.max || 1
        this.is_reversed = options.is_reversed || false
        this.editable = true
        this.draggie.on('dragEnd', this.dragEnd.bind(this))
    }

    dragMove() {
        this.editable = false
    }

    dragEnd() {
        this.editable = true

        if (this.draggie.position.x < this.endPosition * 0.5) {
            if (this.is_reversed) {
                this.enable()

                if (this.state) this.publishCommand()
            } else {
                this.disable()

                if (!this.state) this.publishCommand()
            }
        } else {
            if (this.is_reversed) {
                this.disable()

                if (!this.state) this.publishCommand()
            } else {
                this.enable()

                if (this.state) this.publishCommand()
            }
        }

        if (this.endPosition === this.draggie.position.x) {
            this.$box.style.background = '#c5e1a5'
        }
        if (this.draggie.position.x === 0) {
            this.$box.style.background = '#efeeee'
        }

        //TODO Check this line - is because Overflow of switch from parent
        if (
            this.endPosition === this.draggie.position.x &&
            this.draggie.position.x > this.draggie.containSize.width
        ) {
            this.draggie.setPosition(this.draggie.containSize.width - 5)
        }
    }

    enable() {
        if (!this.editable) {
            return
        }

        this.state = this.max
        this.is_reversed
            ? this.draggie.setPosition(0, 0)
            : this.draggie.setPosition(this.endPosition, 0)
    }

    disable() {
        if (!this.editable) {
            return
        }

        this.state = this.min
        this.is_reversed
            ? this.draggie.setPosition(this.endPosition, 0)
            : this.draggie.setPosition(0, 0)
    }
}

export class HitchControl extends SwitchBase {
    constructor(containerId, { is_reversed = false }, socketClient, oxinId) {
        super(containerId, socketClient, oxinId)
        this.isZeroPublished = false
        this.isOnePublished = true
        this.isTwoPublished = false
        this.isThreePublished = true

        this.draggie.on('dragMove', this.dragMove.bind(this))
        this.draggie.on('dragEnd', this.dragEnd.bind(this))
    }

    dragMove() {
        if (this.state === 0) {
            if (this.position > 66 && !this.isTwoPublished) {
                this.state = 2
                this.publishCommand()
                this.isTwoPublished = true
                this.isThreePublished = false
            }

            if (this.position < 33 && !this.isOnePublished) {
                this.state = 1
                this.publishCommand()
                this.isOnePublished = true
            }
        } else if (this.state === 1) {
            if (this.position > 33 && !this.isZeroPublished) {
                this.state = 0
                this.publishCommand()
                this.isZeroPublished = true
            }
        }
    }

    dragEnd() {
        this.state === 1 ? this.enable() : this.disable()

        if (!this.isThreePublished) {
            this.state = 3
            this.publishCommand()
            this.isThreePublished = true
            this.state = 0
        }

        this.isZeroPublished = false
        this.isOnePublished = false
        this.isTwoPublished = false
    }

    enable() {
        this.state = 1
        this.draggie.setPosition(0, 0)
    }

    disable() {
        this.state = 0
        this.draggie.setPosition(this.endPosition / 2, 0)
    }
}

export class Range extends PercentageSwitch {
    constructor(containerId, { min = 0, max, fix = 2, threshold = 1 }, socketClient, oxinId) {
        super(containerId, { threshold: threshold }, socketClient, oxinId)
        this.min = min
        this.max = max
        this.fix = fix || 2
        this.setPosition(min)
    }

    setValueToHTMLElement() {
        let v = this.min + ((this.max - this.min) * this.percentage) / 100

        if (v < this.min) {
            v = this.min
        } else if (v > this.max) {
            v = this.max
        }

        this.$value_box.innerText = isNaN(v) ? 0 : v
        this.state = v
    }

    setPosition = (value) => {
        const v = ((value - this.min) * 100) / (this.max - this.min)
        super.setPosition(v)
    }
    reset() {
        this.setPosition(0)
    }
}

export class DecimalSwitch extends PercentageSwitch {
    constructor(containerId, { min, max, fix = 2, threshold = 1, start }, socketClient, oxinId) {
        super(containerId, { threshold: threshold }, socketClient, oxinId)
        this.min = min
        this.max = max
        this.fix = fix || 2
        if (start) {
            //Start is not a value, is a percentage.
            this.setPosition(start)
        }
    }

    setValueToHTMLElement() {
        let v = Math.ceil(this.min + ((this.max - this.min) * this.percentage) / 100)
        if (v < this.min) {
            v = this.min
        } else if (v > this.max) {
            v = this.max
        }

        this.state = ((isNaN(v) ? this.min || 0 : v) / 100).toFixed(2)
        this.$value_box.innerText = this.state
    }
}
