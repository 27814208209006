import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import EventButton from './EventButton'
import ModalCountdown from './ModalCountdown'

const Sprayer = (props) => {
    const dosing_expected_amount = 500

    return (
        <Grid container sx={{ width: '100%' }}>
            <Grid
                container
                {...{
                    xs: 12,
                    sm: 8,
                    borderRight: '2px solid #d2c6ba',
                }}
            >
                <Grid xs={12}>
                    <Typography textAlign="center" component="h4" pt={{ sm: 2 }}>
                        OPERATION
                    </Typography>
                </Grid>
                <Grid
                    {...{
                        xs: 12,
                        sm: 6,
                        gap: { sm: 3, xs: 2 },
                        px: { sm: 2, xs: 1 },
                        py: { sm: 2, xs: 1 },
                        display: 'grid',
                    }}
                >
                    <ModalCountdown
                        id="sprayer_purge"
                        label="PURGE"
                        modal={{
                            title: 'sprayer purge',
                            type: 'countdown',
                            seconds: 60,
                            finishAction: 'sprayer_off',
                        }}
                    />
                    <ModalCountdown id="sprayer_calibrate_mass_flow" label="pressure calibration" />
                </Grid>
                <Grid
                    {...{
                        xs: 12,
                        sm: 6,
                        gap: { sm: 3, xs: 2 },
                        px: { sm: 2, xs: 1 },
                        py: { sm: 2, xs: 1 },
                        display: 'grid',
                    }}
                >
                    <ModalCountdown
                        id="spray_prime"
                        label="PRIME"
                        modal={{
                            title: 'Dosing Prime',
                            type: 'countdown',
                            seconds: 60,
                            finishAction: 'sprayer_off',
                        }}
                    />
                    <EventButton id="sprayer_reset_dosing" value="0">
                        RESET DOSING
                    </EventButton>
                </Grid>
            </Grid>

            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { sm: 2, xs: 1 },
                    py: { sm: 2, xs: 1 },
                    gap: { sm: 3, xs: 2 },
                    display: 'grid',
                }}
            >
                <Grid xs={12}>
                    <Typography textAlign="center" component="h4">
                        CALIBRATE
                    </Typography>
                </Grid>
                <ModalCountdown id="sprayer_prime" label="mass flow test" />
                <ModalCountdown id="sprayer_prime" label="update mass flow" />
                <ModalCountdown
                    id="spray_prime_2"
                    action="spray_prime"
                    label="dosing test"
                    modal={{
                        title: 'Dosing Rate Test',
                        type: 'countdown',
                        seconds: 60,
                        subtitle: (
                            <Typography variant="h6" textAlign="center" component="h6">
                                {`Expected ${dosing_expected_amount / 10} mL/min`}
                            </Typography>
                        ),
                        finishAction: 'sprayer_off',
                    }}
                />
            </Grid>
        </Grid>
    )
}

Sprayer.propTypes = {}

export default Sprayer
