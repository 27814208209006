import * as React from 'react'

import Box from '@mui/material/Box'
import { useParams, Outlet, useOutletContext } from 'react-router-dom'
import { SxProps, Theme } from '@mui/material/styles'
import { OxinSelectedProvider } from '../../contexts/OxinSelectedContext'
import { ToolbarAVT } from '../../components'
import { PATHS } from '../../constans'

import { useSocketTelemetry } from '../../hooks'
export default function SharedLayout() {
    const navParams = useParams()
    let styles: SxProps<Theme> = {
        height: 'auto',
        minHeight: '-webkit-fill-available',
        position: 'absolute',
        width: '100%',
    }
    const defaultPadding = { pt: 1, pl: 3, pr: 3, pb: 2 }
    const oxinId = navParams['avtName']?.toLocaleUpperCase()
    const path = navParams['*']

    if (path == 'missions') {
        styles = { p: 0 }
    } else {
        styles = { ...styles, ...defaultPadding }
    }

    return (
        <OxinSelectedProvider value={{ name: oxinId }}>
            <ToolbarAVT oxinId={oxinId} />
            <Box
                component="main"
                sx={{
                    ...styles,
                    top: '4.2rem',
                    overflowY: 'auto',
                    height: 'calc(100vh - 4.2rem)',
                    minHeight: ' -webkit-fill-available',
                }}
            >
                <Outlet />
            </Box>
        </OxinSelectedProvider>
    )
}
