import { useQuery } from '@tanstack/react-query'
import Oxin from '../api/endpoints/Oxin'
export const useOxin = (id) => {
    try {
        const data = useQuery({
            queryKey: [`oxinId`, id],
            queryFn: async () => {
                // myParameter can be undefined, skip call if that's the case
                if (!id) return
                const data = await Oxin.getOxin(id)
                return data?.data || {}
            },
            staleTime: 1000 * 60 * 60,
        })
        return data
    } catch (error) {
        console.log('src/hooks/useOxin.tsx error', error)
        return {}
    }
}

export const useOxinLastReportData = (id, options = {}) => {
    try {
        const data = useQuery({
            queryKey: [`oxinId-last-report`, id],
            queryFn: async () => {
                // myParameter can be undefined, skip call if that's the case
                if (!id) return
                const data = await Oxin.getOxinLastData(id)
                return data?.data || {}
            },
            ...options,
        })
        return data
    } catch (error) {
        console.log('src/hooks/useOxin.tsx useOxinLastReportData error', error)
        return {}
    }
}

export const useOxinLastLogs = (id, options = {}) => {
    try {
        const data = useQuery({
            queryKey: [`oxinId-last-logs`, id],
            queryFn: async () => {
                // myParameter can be undefined, skip call if that's the case
                if (!id) return
                const data = await Oxin.getOxinLastLogs(id)
                return data?.data || []
            },
            ...options,
        })
        return data
    } catch (error) {
        console.log('src/hooks/useOxin.tsx useOxinLastLogs error', error)
        return {}
    }
}

export const useOxinLocation = (id, options = {}) => {
    try {
        const data = useQuery({
            queryKey: [`oxinId-last-location`, id],
            queryFn: async () => {
                // myParameter can be undefined, skip call if that's the case
                if (!id) return
                const data = await Oxin.getLocation(id)
                if (data?.data?.lat && data?.data?.lng) {
                    const coord = [data?.data?.lat, data?.data?.lng]
                    return coord
                } else {
                    return []
                }
            },
            initialData: [],
            ...options,
        })
        return data
    } catch (error) {
        console.log('src/hooks/useOxin.tsx useOxinLocation error', error)
        return {}
    }
}
