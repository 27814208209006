import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { TabPanel, a11yProps } from '../../ui-components'

import Carriage from './Carriage'
import Sprayer from './Sprayer'
import Blower from './Blower'
import Fan from './Fan'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsToDot, faFan, faWind, faDroplet } from '@fortawesome/free-solid-svg-icons'
import styles from './Tabs.module.scss'

export const ImplementTabs = () => {
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Paper sx={{ width: '100%' }} className={styles.root} elevation={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} className={styles.header}>
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faArrowsToDot} size="sm" />}
                        iconPosition="start"
                        label={'Carriage'}
                        {...a11yProps('implements-carriage', 0)}
                    />
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faDroplet} size="sm" />}
                        iconPosition="start"
                        label={'Sprayer'}
                        {...a11yProps('implements-sprayer', 1)}
                    />
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faWind} size="sm" />}
                        iconPosition="start"
                        label={'Blower'}
                        {...a11yProps('implements-blower', 2)}
                    />
                    <Tab
                        className={styles.tab}
                        icon={<FontAwesomeIcon icon={faFan} size="sm" />}
                        iconPosition="start"
                        label={'Fan'}
                        {...a11yProps('implements-fan', 3)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} id={'implements-carriage'}>
                <Carriage />
            </TabPanel>
            <TabPanel value={value} index={1} id={'implements-sprayer'}>
                <Sprayer />
            </TabPanel>
            <TabPanel value={value} index={2} id={'implements-blower'}>
                <Blower />
            </TabPanel>
            <TabPanel value={value} index={3} id={'implements-fan'}>
                <Fan />
            </TabPanel>
        </Paper>
    )
}
