import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import Typography from '@mui/material/Typography'
import { OneShotButton } from '../../js'

import { useSocket } from '../../contexts/SocketContext'
import { useOxinSelected } from '../../contexts/OxinSelectedContext'

const ButtonCarriage = ({ id, icon, value }) => {
    const { name: oxinId } = useOxinSelected()
    const socketClient = useSocket()
    const buttonId = `implement-button-carriage-${value}-${id}`
    useEffect(() => {
        const resetButton = new OneShotButton(buttonId, { stateValue: value }, socketClient, oxinId)
        return () => {
            resetButton.destroy()
        }
    }, [])

    return (
        <Button
            id={buttonId}
            data-action={id}
            data-state={value}
            variant="contained"
            size="large"
            sx={{ width: { sm: '7rem' }, height: { sm: '5rem' } }}
        >
            <FontAwesomeIcon icon={icon} className="fa-2xl" />
        </Button>
    )
}

const Carriage = (props) => {
    return (
        <Grid container sx={{ width: '100%' }}>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { md: 6, sm: 4, xs: 2, lg: 4 },
                    my: { sm: 4, xs: 2 },
                    textAlign: 'center',
                    justifyContent: 'center',
                    gap: { xs: '2rem', sm: '4rem' },
                    display: 'inline-flex',
                }}
            >
                <ButtonCarriage id={'lh_arm_control'} value={1} icon={faAngleLeft} />
                <ButtonCarriage id={'lh_arm_control'} value={0} icon={faAngleRight} />
            </Grid>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { md: 6, sm: 4, xs: 2, lg: 4 },
                    textAlign: 'center',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                }}
            >
                <Typography textAlign="center" component="h1" variant="h5">
                    CARRIAGE
                </Typography>
            </Grid>
            <Grid
                {...{
                    xs: 12,
                    sm: 4,
                    px: { md: 6, sm: 4, xs: 2, lg: 4 },
                    my: { sm: 4, xs: 2 },
                    textAlign: 'center',
                    justifyContent: 'center',
                    gap: { xs: '2rem', sm: '4rem' },
                    display: 'inline-flex',
                }}
            >
                <ButtonCarriage id={'rh_arm_control'} value={0} icon={faAngleLeft} />
                <ButtonCarriage id={'rh_arm_control'} value={1} icon={faAngleRight} />
            </Grid>
        </Grid>
    )
}

Carriage.propTypes = {}

export default Carriage
