import React, { useEffect, useState, useRef } from 'react'
import { Amplify, Auth } from 'aws-amplify'

import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { theme } from './assets/themes/default'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Loading } from './ui-components'

import SharedLayout from './components/SharedLayout/SharedLayout'
import { AuthProvider } from './lib/AuthProvider'
import DrivePage from './views/Drive'
import ImplementsPage from './views/Implements'
import MainPage from './views/Main'
import MissionsPage from './views/Missions'
import NotFoundPage from './views/NotFoundPage'
import SettingsPage from './views/Settings'
import CameraPage from './views/Camera/Camera'

import InfoPage from './views/Info'

import { useUserInitDataUser, useLocalStorage } from './hooks'
import { UserProvider } from './contexts/UserContext'
import { SocketProvider } from './contexts/SocketContext'
import { VehiclesProvider } from './contexts/VehiclesContext'

import AppConfig from './constans'
import io from 'socket.io-client'

const LOCALSTORAGE_USER_DATA = 'oxin_user_config'

function AnimaApp({ user, signOut }) {
    //TODO change this
    const client = new QueryClient()
    const { data, isLoading } = useUserInitDataUser()
    const [isConnected, setConnected] = useState(false)
    window.$__intervals = []
    const socketUrl = `${AppConfig.apiUrl}`
    const socket = useRef(null)
    const _handlerSignOut = () => {
        _handlerDisconnect()
        signOut()
    }
    const _handlerDisconnect = () => {
        if (socket.current && socket.current.connected) {
            window.$__intervals?.forEach((id) => {
                window.clearInterval(id)
            })
            socket.current.emit('user_offline')
            socket.current.off()
            socket.current.disconnect()
            socket.current.close()
            console.warn('DISCONNECT SOCKET')
        } else {
            console.warn('ANY SOCKET')
        }
        setConnected(false)
    }
    useEffect(() => {
        _handlerDisconnect()
        console.log('SOCKET AGAIN')
        /* =============== SOCKET  INIT ===================== */
        socket.current = io(socketUrl, {
            autoConnect: true,
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: 100,
            reconnectionDelay: 3000,
            withCredentials: true,
            auth: (cb) => {
                Auth.currentSession()
                    .then((authdata) => {
                        cb({
                            Authorization: `Bearer ${authdata.getIdToken().getJwtToken()}`,
                            email: user.attributes.email,
                        })
                    })
                    .catch((e) => {
                        console.error('Error socket auth', e)
                        cb({})
                        //Auth.signOut()
                    })
            },
        })
        console.log('SOCKET IO - connecting', socket.current)

        socket.current.on('connect', () => {
            console.info(`Successfully connected to socket at ${socketUrl}`)
            setConnected(true)
        })

        socket.current.on('disconnect', () => {
            console.info(`Successfully disconnected`)
            setConnected(false)
        })

        socket.current.on('error', (err) => {
            console.log('Socket Error:', err.message)
        })

        socket.current.on('connect_error', (message) =>
            console.warn('Socket.io: connect_error: ', message)
        )
        socket.current.on('connect_error', (err) => {
            console.log(err instanceof Error) // true
            console.log(err.message) // not authorized
            console.log(err.data) // { content: "Please retry later" }
        })
        socket.current.on('reconnecting', (message) =>
            console.warn('Socket.io: reconnecting...', message)
        )
        socket.current.on('reconnect_error', (message) =>
            console.warn('Socket.io: reconnect_error: ', message)
        )
        socket.current.on('reconnect_failed', (message) =>
            console.warn('Socket.io: reconnect_failed: ', message)
        )
        socket.current.on('reconnect', (message) => {
            socket.emit('join_notifications_rom')
        })

        /* ================ PANIC BUTTONS ==================== */
        /*let longPress = new LongPressP1Handler(() => vm.publishCommand({action: 'engine_control', value: 0}));
        let panicPress = new PaincPressHandler(() => vm.remoteShutdownAll());

        document.addEventListener("keydown", e => {
          // console.log(e.code);
          if (e.code === "Digit0" || e.code === "F24") {
            longPress.load();
            panicPress.down();
          }
        })

        document.addEventListener("keyup", e => {
          if (e.code === "Digit0" || e.code === "F24") {
            longPress.shutdown();
            panicPress.up();
          }
        })*/

        return () => {
            _handlerDisconnect()
        }
        //TODO CLEAR LOCALSTORAGE && WHEN LOGOUT
    }, [])

    return (
        <>
            <ThemeProvider theme={theme}>
                {isLoading ? (
                    <Loading />
                ) : (
                    <SocketProvider value={socket.current}>
                        <UserProvider value={{ ...data }}>
                            <VehiclesProvider value={{ oxins: data.oxin, socket: socket.current }}>
                                <AuthProvider value={{ user, signOut }}>
                                    <QueryClientProvider client={client}>
                                        <BrowserRouter>
                                            <Routes>
                                                {user ? (
                                                    <>
                                                        <Route exac path="/">
                                                            <Route
                                                                index
                                                                element={
                                                                    <MainPage
                                                                        user={user}
                                                                        signOut={signOut}
                                                                    />
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            path="/:avtName/*"
                                                            element={
                                                                <SharedLayout
                                                                    user={user}
                                                                    signOut={signOut}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path="drive"
                                                                element={<DrivePage />}
                                                            />
                                                            <Route
                                                                path="missions"
                                                                element={<MissionsPage />}
                                                            />
                                                            <Route
                                                                path="implements"
                                                                element={<ImplementsPage />}
                                                            />
                                                            <Route
                                                                path="info"
                                                                element={<InfoPage />}
                                                            />
                                                            <Route
                                                                path="camera"
                                                                element={<CameraPage />}
                                                            />

                                                            <Route
                                                                path="*"
                                                                element={<NotFoundPage />}
                                                            />
                                                        </Route>
                                                        <Route
                                                            path="*"
                                                            element={<NotFoundPage />}
                                                        />
                                                    </>
                                                ) : (
                                                    <Loading />
                                                )}
                                            </Routes>
                                        </BrowserRouter>
                                        <ToastContainer
                                            position="top-center"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                            theme="colored"
                                            draggablePercent={60}
                                            style={{ width: '30rem' }}
                                        />
                                    </QueryClientProvider>
                                </AuthProvider>
                            </VehiclesProvider>
                        </UserProvider>
                    </SocketProvider>
                )}
            </ThemeProvider>
        </>
    )
}

export default AnimaApp
